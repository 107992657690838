import React, { useState, useRef } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import TemporaryDrawer from './tempdrawer';
import ImagePanel from './ImagePanel';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Cookies from 'universal-cookie';
import Grid from '@mui/material/Grid';
import { BASE_URL } from './constants';
import CircularProgress from '@mui/material/CircularProgress';

const cookies = new Cookies();

const CreateCity = () => {
  const [formValues, setFormValues] = useState({
    cityName: '', // Changed from 'city' to 'cityName'
  });
  const [selectedThumbnail, setSelectedThumbnail] = useState(null);
  const [selectedFlyinGraphic, setSelectedFlyinGraphic] = useState(null);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const fileInputRef = useRef(null);
  const fileInputRefFlyin = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showThumbnailError, setShowThumbnailError] = useState(false);
  const [showCityNameError, setShowCityNameError] = useState(false);
  const [audioUrl, setAudioUrl] = useState('');
  const fileInputRefSupportAudio = useRef(null);
  const fileInputRefLoopGraphic = useRef(null);
  const [loading, setLoading] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleCloseSnackbar = () => {
    setShowSnackbar(false);
  };

  const handleFileChangeSupportAudio = (event) => {
    const file = event.target.files[0];
    setFormValues((prevValues) => ({
      ...prevValues,
      supportAudio: file,
    }));
    setAudioUrl(URL.createObjectURL(file)); // Set audio URL for player
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const newImage = { file: URL.createObjectURL(file) };
    setSelectedThumbnail(newImage.file);
    fileInputRef.current.value = ''; // Clear the file input
  };

  const handleFileChangeFlyin = (event) => {
    const file = event.target.files[0];
    setSelectedFlyinGraphic(file);
    fileInputRefFlyin.current.value = '';
  };

  const handleFileChangeLoopGraphic = (event) => {
    const file = event.target.files[0];
    setFormValues((prevValues) => ({
      ...prevValues,
      loopGraphic: file,
    }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formValues.cityName) {
      setShowCityNameError(true);
      return;
    }

    if (!selectedThumbnail) {
      setShowThumbnailError(true);
      return;
    }
    setShowCityNameError(false);
    setShowThumbnailError(false);
    setLoading(true); 
    try {
      const token = cookies.get('token');
      if (!token) {
        console.error('Token not found in cookie');
        setLoading(false); 
        return;
      }

      const formData = new FormData();
      formData.append('name', formValues.cityName);

      if (selectedThumbnail) {
      const responseThumbnail = await fetch(selectedThumbnail);
      const blobThumbnail = await responseThumbnail.blob();
      const urlParts = selectedThumbnail.split('/');
      let filename = urlParts[urlParts.length - 1]; 
      if (!filename.includes('.')) {
        filename += '.jpg';
      }      
      const fileThumbnail = new File([blobThumbnail], filename, { type: 'image/jpeg' });
      formData.append('cityscape', fileThumbnail);
      }

      if (selectedFlyinGraphic) {
        formData.append('flyin_graphic', selectedFlyinGraphic);
      }
      if (formValues.supportAudio) {
        formData.append('support_audio', formValues.supportAudio);
      }

      if (formValues.loopGraphic) {
        formData.append('loop_graphic', formValues.loopGraphic);
      }
      const response = await fetch(`${BASE_URL}/api/v1/city/`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });
      
      if (response.ok) {
        setShowSnackbar(true);
        setSnackbarSeverity('success');
        setSnackbarMessage(
          'City has been created and is in review. It will be published once it is approved.'
        );
        setFormValues({
          cityName: '', 
          supportAudio: null,
          loopGraphic: null,// Reset city name
        });
        setSelectedThumbnail(null); // Reset selected thumbnail
        setSelectedFlyinGraphic(null);
        setAudioUrl(''); // Reset selected flyin graphic
      } else {
        setShowSnackbar(true);
        setSnackbarSeverity('error');
        setSnackbarMessage('Error creating city. Please try again.');
      }
    } catch (error) {
      console.error('Error creating city:', error);
      setShowSnackbar(true);
      setSnackbarSeverity('error');
      setSnackbarMessage('An error occurred. Please try again.');
    } finally {
      setLoading(false); // this is new line
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
      <Box sx={{ flexGrow: 1 }}>
      <Typography variant="h4" gutterBottom>
        Create New City
      </Typography>
        <br></br>
        <Grid container spacing={2}>
          <Grid item xs={6} md={2}>
            <Typography variant="subtitle1" sx={{ marginTop: 2 }}>City Name* : </Typography>
          </Grid>
          <Grid item xs={6} md={6}>
            <TextField
              label="City Name *"
              variant="outlined"
              fullWidth
              sx={{ marginBottom: 2 }}
              name="cityName" // Changed from 'city' to 'cityName'
              value={formValues.cityName}
              onChange={handleInputChange}
              error={showCityNameError}
              helperText={showCityNameError && 'City Name is required'}
            />
          </Grid>
          <Grid item xs={6} md={4}></Grid>
          
          <Grid item xs={6} md={2}>
            <Typography variant="subtitle1" sx={{ marginTop: 2 }}>City Thumbnail* : </Typography>
          </Grid>
          <Grid item xs={6} md={6}>
            {selectedThumbnail && (
                <Box sx={{ alignItems: 'center', marginTop: 2 }}>
                  <img
                    src={selectedThumbnail}
                    alt="Thumbnail"
                    style={{ width: '150px' }}
                  />
                </Box>
              )}
              <Button variant="contained" component="label" sx={{ marginTop: 2, color: 'white' ,textTransform: 'none'}}>
                Upload City Thumbnail
                <input
                  type="file"
                  accept=".jpeg, .jpg, .png, .gif"
                  ref={fileInputRef}
                  style={{ display: 'none' }}
                  onChange={handleFileChange}
                />
              </Button>
              <br></br>
              {showThumbnailError && (
                <Typography variant="caption" color="error">
                  Thumbnail is required
                </Typography>
              )}
          </Grid>
          <Grid item xs={6} md={4}></Grid>

          <Grid item xs={6} md={2}>
            <Typography variant="subtitle1">Flyin Graphic  :</Typography>
          </Grid>
          <Grid item xs={6} md={6}>
            {selectedFlyinGraphic && (
              <Typography variant="body1">{selectedFlyinGraphic.name}</Typography>
            )}
            <Button variant="contained" component="label" sx={{ marginTop: 2,  color: 'white', textTransform: 'none' }}>
              Upload Flyin Graphic
              <input
                type="file"
                accept=".mp4, .avi, .mov"
                ref={fileInputRefFlyin}
                style={{ display: 'none' }}
                onChange={handleFileChangeFlyin}
              />
            </Button>
          </Grid>
          <Grid item xs={6} md={4}></Grid>
          
          <Grid item xs={6} md={2}>
            <Typography variant="subtitle1">Loop Graphic  :</Typography>
          </Grid>
          <Grid item xs={6} md={6}>
          {formValues.loopGraphic && (
            <Typography variant="body1">
              {formValues.loopGraphic.name}
            </Typography>
          )}
            <Button variant="contained" component="label" sx={{ marginTop: 2,  color: 'white', textTransform: 'none' }}>
              Upload Loop Graphic
              <input
                type="file"
                ref={fileInputRefLoopGraphic}
                style={{ display: 'none' }}
                onChange={handleFileChangeLoopGraphic}
              />
            </Button>
          </Grid>
          <Grid item xs={6} md={4}></Grid>
          <Grid item xs={6} md={2}>
            <Typography variant="subtitle1" sx={{ marginTop: 2 }}>Support Audio : </Typography>
          </Grid>
          <Grid item xs={6} md={6}>
            {audioUrl && (
              <audio controls src={audioUrl} >
                Your browser does not support the audio element.
              </audio>
            )}
            <br></br>
            <Button variant="contained" component="label" sx={{ marginTop: 2,  color: 'white',textTransform: 'none', }}>
              Upload Support Audio
              <input
                type="file"
                accept=".mp3"
                ref={fileInputRefSupportAudio}
                style={{ display: 'none' }}
                onChange={handleFileChangeSupportAudio}
              />
            </Button>
          </Grid>
          <Grid item xs={6} md={4}></Grid>

          <Grid item xs={6} md={2}></Grid>
          <Grid item xs={6} md={2}></Grid>
          
          <Grid item xs={6} md={6}>
          <Button
                variant="contained"
                type="submit"
                sx={{ backgroundColor: 'black', color: 'white', align: 'center', marginTop: '50px' }}
                disabled={loading} // this is new line
              >
                {loading ? <CircularProgress size={24} sx={{ color: 'white' }} /> : 'Create City'} 
              </Button>
          </Grid>
          <Grid item xs={6} md={4}></Grid>

          
        </Grid>
      </Box>
        
        <Box sx={{ maxWidth: 600, margin: 'auto', textAlign: 'left', padding: 3, marginLeft: '10px' }}>
          {/* <TextField
            label="City Name *"
            variant="outlined"
            fullWidth
            sx={{ marginBottom: 2 }}
            name="cityName" // Changed from 'city' to 'cityName'
            value={formValues.cityName}
            onChange={handleInputChange}
          />
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '1rem' }}>
            <Typography variant="subtitle1" sx={{ marginTop: 2 }}>City Thumbnail* : </Typography>
            {selectedThumbnail && (
              <Box sx={{ alignItems: 'center', marginTop: 2 }}>
                <img
                  src={selectedThumbnail}
                  alt="Thumbnail"
                  style={{ width: '150px', height: '150px' }}
                />
              </Box>
            )}
          </Box>

          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '1rem', marginTop: 2 }}>
            <Typography variant="subtitle1">Flyin Graphic * :</Typography>
             {selectedFlyinGraphic && (
              <Typography variant="body1">{selectedFlyinGraphic.name}</Typography>
            )}
            <Button variant="contained" component="label" sx={{ marginTop: 2, backgroundColor: 'black', color: 'white' }}>
              Upload Flyin Graphic
              <input
                type="file"
                accept=".mp4, .avi, .mov"
                ref={fileInputRefFlyin}
                style={{ display: 'none' }}
                onChange={handleFileChangeFlyin}
              />
            </Button>
          </Box>

          <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2 }}>
            <Button variant="contained" type="submit" sx={{ backgroundColor: 'black', color: 'white' }}>
              Create City
            </Button>
          </Box> */}

          <TemporaryDrawer
            onSeeAllClick={() => setIsModalOpen(true)}
            onSelectThumbnail={(image) => setSelectedThumbnail(image)}
          />

          <ImagePanel
            isOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            onSelectThumbnail={(image) => setSelectedThumbnail(image)}
          />

          <Snackbar
            open={showSnackbar}
            autoHideDuration={6000}
            onClose={handleCloseSnackbar}
          >
            <MuiAlert
              elevation={6}
              variant="filled"
              onClose={handleCloseSnackbar}
              severity={snackbarSeverity}
            >
              {snackbarMessage}
            </MuiAlert>
          </Snackbar>
        </Box>
      </form>
    </>
  );
};

export default CreateCity;
