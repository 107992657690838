import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Cookies from 'js-cookie';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { BASE_URL } from './constants';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';

const AddEvent = ({ onClose }) => {
  const [eventData, setEventData] = useState({
    name: '',
    description: '',
    roomId: '',
    event_start_date: '',
    event_start_time: '',
    event_end_date: '',
    event_end_time: ''
  });
  const [loading, setLoading] = useState(false);
  const [rooms, setRooms] = useState([]);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [snackbarMessage, setSnackbarMessage] = useState('');
    const [errors, setErrors] = useState({
      name: false,
      roomId: false,
      event_start_date: false,
      event_start_time: false,
      event_end_date: false,
      event_end_time: false
    });
  useEffect(() => {
    const fetchRooms = async () => {
      try {
        const token = localStorage.getItem('token') || Cookies.get('token') ;
        const response = await fetch(`${BASE_URL}/api/v1/rooms/`, {
          headers: {
            Authorization: `Bearer ${token}`, // Include the token in the Authorization header
          },
        });
        if (!response.ok) {
          throw new Error('Failed to fetch rooms');
        }
        const roomsData = await response.json();
        setRooms(roomsData.results); // Assuming 'results' contains an array of rooms
      } catch (error) {
        console.error('Error fetching rooms:', error);
      }
    };

    fetchRooms();
  }, []);

const handleChange = (e) => {
    const { name, value } = e.target;
    let newValue = value;
  
    // Check if the field is for date or time
    if (name.includes('_date')) {
      const currentDate = new Date().toISOString().split('T')[0]; // Get current date in "yyyy-mm-dd" format
      newValue = value < currentDate ? currentDate : value; // Reset to current date if selected date is in the past
    } else if (name.includes('_time')) {
      const currentTime = new Date().toLocaleTimeString('en-GB', { hour12: false }); // Get current time in "hh:mm:ss" format
      const selectedTime = new Date(`1970-01-01T${value}`); // Create Date object with selected time
      const currentTimeObj = new Date(`1970-01-01T${currentTime}`); // Create Date object with current time
  
      // Check if the selected time is before the current time
      if (selectedTime < currentTimeObj) {
        const hours = currentTimeObj.getHours().toString().padStart(2, '0');
        const minutes = currentTimeObj.getMinutes().toString().padStart(2, '0');
        const seconds = currentTimeObj.getSeconds().toString().padStart(2, '0');
        newValue = `${hours}:${minutes}:${seconds}`; // Reset to current time if selected time is in the past
      }
    }
  
    setEventData(prevData => ({
      ...prevData,
      [name]: newValue,
    }));
  };
  
  const handleSubmit = async () => {
      try {
        const requiredFields = ['name', 'roomId', 'event_start_date', 'event_start_time', 'event_end_date', 'event_end_time'];
        const newErrors = {};
        let hasErrors = false;
  
        requiredFields.forEach(field => {
          if (!eventData[field]) {
            newErrors[field] = true;
            hasErrors = true;
          } else {
            newErrors[field] = false;
          }
        });
  
        if (hasErrors) {
          setErrors(newErrors);
          return;
        }
  
        const role = Cookies.get('role');
        const isAdmin = Cookies.get('isAdmin');
        if (role != 'ccgadmin' && role != 'content_creator') {
          setShowSnackbar(true);
          setSnackbarSeverity('error');
          setSnackbarMessage('You must be a content creator or an admin to create an event.');
          return;
        }
        // Create event data object to send
        const eventDataToSend = {
          name: eventData.name,
          description: eventData.description,
          room: eventData.roomId,
          event_start_date: eventData.event_start_date,
          event_start_time: eventData.event_start_time,
          event_end_date: eventData.event_end_date,
          event_end_time: eventData.event_end_time
        };
  
        // console.log('Data being sent:', eventDataToSend);
        setLoading(true);
        // Make POST request to create event
        const token = Cookies.get('token'); 
        const response = await fetch(`${BASE_URL}/api/v1/events/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(eventDataToSend),
        });
  
        if (!response.ok) {
          throw new Error('Failed to create event');
        }
  
        setShowSnackbar(true);
        setSnackbarSeverity('success');
        setSnackbarMessage('Event created successfully. It will be published once its reviewed');
        setTimeout(() => setShowSnackbar(false), 6000);
        resetForm();
      } catch (error) {
        console.error('Error creating event:', error);
        setShowSnackbar(true);
        setSnackbarSeverity('error');
        setSnackbarMessage('An error occurred. Please try again.');
        setLoading(false);
      } finally {
        setLoading(false); // Stop loading regardless of success or failure
      }
    };

  const resetForm = () => {
    setEventData({
      name: '',
      description: '',
      roomId: '',
      event_start_date: '',
      event_start_time: '',
      event_end_date: '',
      event_end_time: ''
    });
    setErrors({
      name: false,
      roomId: false,
      event_start_date: false,
      event_start_time: false,
      event_end_date: false,
      event_end_time: false
    });
  };

 const handleCloseSnackbar = () => {
  setShowSnackbar(false);
};
return (
      <Dialog open={true} onClose={onClose} fullWidth maxWidth="sm">
        <DialogTitle>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="h6">Add Event</Typography>
            <Button onClick={onClose} startIcon={<CloseIcon />} style={{ backgroundColor: '#fdd835', color: '#424242', fontWeight: 'bold', boxShadow: 'none' }}>Close</Button>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="subtitle1" style={{ marginBottom: '8px', color: '#424242', fontWeight: 'bold' }}>Event Name *</Typography>
              <TextField
                name="name"
                value={eventData.name}
                onChange={handleChange}
                fullWidth
                variant="outlined"
                error={errors.name}
                helperText={errors.name && "Please fill in this field"}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1" style={{ marginBottom: '8px', color: '#424242', fontWeight: 'bold' }}>Description</Typography>
              <TextField
                name="description"
                value={eventData.description}
                onChange={handleChange}
                multiline
                fullWidth
                rows={4}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1" style={{ marginBottom: '8px', color: '#424242', fontWeight: 'bold' }}>Room Name *</Typography>
              <select
                value={eventData.roomId}
                onChange={handleChange}
                name="roomId"
                style={{ width: '100%', padding: '10px', borderRadius: '4px', border: '1px solid #ccc', backgroundColor: errors.roomId ? '#ffcccc' : '' }}
              >
                <option value="">Select a room</option>
                {rooms.map(room => (
                  <option key={room.id} value={room.id}>{room.name}</option>
                ))}
              </select>
              {errors.roomId && <Typography variant="body2" style={{ color: 'red' }}>Please select a room</Typography>}
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle1" style={{ marginBottom: '8px', color: '#424242', fontWeight: 'bold' }}>Start Date *</Typography>
              <TextField
                type="date"
                name="event_start_date"
                value={eventData.event_start_date}
                onChange={handleChange}
                fullWidth
                variant="outlined"
                error={errors.event_start_date}
                helperText={errors.event_start_date && "Please fill in this field"}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle1" style={{ marginBottom: '8px', color: '#424242', fontWeight: 'bold' }}>Start Time *</Typography>
              <TextField
                type="time"
                name="event_start_time"
                value={eventData.event_start_time}
                onChange={handleChange}
                fullWidth
                variant="outlined"
                error={errors.event_start_time}
                helperText={errors.event_start_time && "Please fill in this field"}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle1" style={{ marginBottom: '8px', color: '#424242', fontWeight: 'bold' }}>End Date *</Typography>
              <TextField
                type="date"
                name="event_end_date"
                value={eventData.event_end_date}
                onChange={handleChange}
                fullWidth
                variant="outlined"
                error={errors.event_end_date}
                helperText={errors.event_end_date && "Please fill in this field"}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle1" style={{ marginBottom: '8px', color: '#424242', fontWeight: 'bold' }}>End Time *</Typography>
              <TextField
                type="time"
                name="event_end_time"
                value={eventData.event_end_time}
                onChange={handleChange}
                fullWidth
                variant="outlined"
                error={errors.event_end_time}
                helperText={errors.event_end_time && "Please fill in this field"}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" onClick={handleSubmit}  disabled={loading}  style={{ borderRadius: '20px', fontWeight: 'bold', boxShadow: 'none',textTransform: 'none', }}>  {loading && <CircularProgress size={24} style={{ position: 'absolute', top: '50%', left: '50%', marginTop: -12, marginLeft: -12, color: 'white' }} />}Submit</Button>
        </DialogActions>
        <Snackbar
          open={showSnackbar}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
        >
          <MuiAlert
            elevation={6}
            variant="filled"
            onClose={handleCloseSnackbar}
            severity={snackbarSeverity}
          >
            {snackbarMessage}
          </MuiAlert>
        </Snackbar>
      </Dialog>
    );
  };
  
export default AddEvent;