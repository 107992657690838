// import React, { useState, useEffect } from 'react';
// import Typography from '@mui/material/Typography';
// import Box from '@mui/material/Box';
// import Button from '@mui/material/Button';
// import TableCell from '@mui/material/TableCell';
// import TableContainer from '@mui/material/TableContainer';
// import TableHead from '@mui/material/TableHead';
// import TableBody from '@mui/material/TableBody';
// import TableRow from '@mui/material/TableRow';
// // import EditCityForm from './EditCityForm'; // Import the EditCityForm component
// import { useParams } from 'react-router-dom'; // Import useParams from react-router-dom
// import Cookies from 'universal-cookie'; // Import universal-cookie for managing cookies
// import { BASE_URL } from './constants';
// // import CityComponents from './CityComponents'; // Import CityComponents
// import CircularProgress from '@mui/material/CircularProgress';
// import Table from '@mui/material/Table';
// import RoomComponents from './RoomComponents';
// import EditCityForm from './EditCityForm';
// import CityComponents from './CityComponents';
// import DeleteIcon from '@mui/icons-material/Delete';
// import EditIcon from '@mui/icons-material/Edit';

// const cookies = new Cookies(); // Initialize cookies

// const Cities = () => {
//   const [cities, setCities] = useState([]); // Change rooms to cities
//   const [editCityId, setEditCityId] = useState(null); // Change editRoomId to editCityId
//   const [showCityComponents, setShowCityComponents] = useState(false); // Change showRoomComponents to showCityComponents
//   const [selectedCity, setSelectedCity] = useState(null); // Change selectedRoom to selectedCity
//   const { cityId } = useParams(); // Change roomId to cityId

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const isAdminCookie = cookies.get('isAdmin');
//         let endpoint;
//         if (isAdminCookie.toString() === 'true') {
//           endpoint = `${BASE_URL}/api/v1/city/`; // Override endpoint for admin
//         } else {
//           endpoint = `${BASE_URL}/api/v1/city-list/`; // Default endpoint for non-admin
//         }
//         const token = cookies.get('token'); // Get the token from the cookie
//         const response = await fetch(endpoint, { // Fetch cities from the determined endpoint
//           headers: {
//             Authorization: `Bearer ${token}`, // Include the token in the Authorization header
//           },
//         });
//         if (!response.ok) {
//           throw new Error('Failed to fetch cities');
//         }
//         const data = await response.json();
//         setCities(data.results);
//       } catch (error) {
//         console.error('Error fetching cities:', error);
//       }
//     };
  
//     fetchData();
//   }, []);
  


//   const formatDate = (dateString) => {
//     const date = new Date(dateString);
//     return date.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' });
//   };

//   const handleEditCity = (cityId) => {
//     setEditCityId(cityId);
//   };

//   const handleCancelEdit = () => {
//     setEditCityId(null);
//   };

//   const generateCityMapperLink = (cityId) => {
//     return `${BASE_URL}/city/mapper/${cityId}`;
//   };

//   const handleShowCityComponents = async (cityId) => {
//     try {
//       const token = cookies.get('token'); // Get the token from the cookie
//       const response = await fetch(`${BASE_URL}/api/v1/city-detail/${cityId}/`, {
//         headers: {
//           Authorization: `Bearer ${token}`, // Include the token in the Authorization header
//         },
//       });
//       if (!response.ok) {
//         throw new Error('Failed to fetch city components');
//       }
//       const data = await response.json();
//       console.log("CC",data);
//       setSelectedCity(data);
//       setShowCityComponents(true);
//     } catch (error) {
//       console.error('Error fetching city components:', error);
//     }
//   };

//   const handleCloseCityComponents = () => {
//     setShowCityComponents(false);
//   };
  
//   const handleDeleteCity = async (cityId) => {
//     try {
//       // Ask for confirmation before deleting
//       const confirmed = window.confirm("Are you sure you want to delete this city? This action won't be reversible.");
//       if (!confirmed) return;
  
//       const token = cookies.get('token');
//       const response = await fetch(`${BASE_URL}/api/v1/city/${cityId}/`, {
//         method: 'DELETE',
//         headers: {
//           Authorization: `Bearer ${token}`,
//         },
//       });
//       if (!response.ok) {
//         throw new Error('Failed to delete city');
//       }
//       // Show success alert after deleting
//       window.alert("City deleted successfully.");
  
//       // Remove the deleted city from the cities array
//       setCities(cities.filter(city => city.id !== cityId));
//     } catch (error) {
//       console.error('Error deleting city:', error);
//       // Show error alert if deletion fails
//       window.alert("Failed to delete city. Please try again later.");
//     }
//   };
  

//   const stateMap = {
//     draft: 'Draft',
//     in_review: 'In-Review',
//     published: 'Published'
//   };

//   return (
//     <Box>
//       {showCityComponents ? (
//         <CityComponents cityData={selectedCity} onClose={handleCloseCityComponents} />
//       ) : (
//         <>
//           <Typography variant="h5" gutterBottom>
//             Your Cities
//           </Typography>
//           {editCityId ? (
//             <EditCityForm cityId={editCityId} onClose={handleCancelEdit} /> // Render EditCityForm if editCityId is not null
//           ) : (
//             <TableContainer>
//               <Table>
//                 <TableHead>
//                   <TableRow>
//                     <TableCell style={{ fontWeight: 'bold' }}>City Thumbnail</TableCell>
//                     <TableCell style={{ fontWeight: 'bold' }}>City Name</TableCell>
//                     <TableCell style={{ fontWeight: 'bold' }}>Created At</TableCell>
//                     <TableCell style={{ fontWeight: 'bold' }}>State</TableCell>
//                     <TableCell style={{ fontWeight: 'bold' }}>Actions</TableCell>
//                   </TableRow>
//                 </TableHead>
//                 <TableBody>
//                   {cities.length === 0 ? ( // Display loading indicator if cities array is empty
//                     <TableRow>
//                       <TableCell colSpan={5} style={{ textAlign: 'center' }}>
//                         <CircularProgress />
//                       </TableCell>
//                     </TableRow>
//                   ) : (
//                     cities.map(city => (
//                       <TableRow key={city.id}>
//                         <TableCell>
//                           <img src={city.cityscape} alt={city.name} style={{ width: 100 }} /> {/* Change room_thumbnail to city_thumbnail */}
//                         </TableCell>
//                         <TableCell>{city.name}</TableCell>
//                         <TableCell>{formatDate(city.created_at)}</TableCell>
//                         <TableCell>{stateMap[city.state]}</TableCell>
//                         <TableCell>
//                           <Button variant="contained" color="primary" style={{ marginRight: 7,textTransform: 'none' }} startIcon={<EditIcon />} onClick={() => handleEditCity(city.id)}>Edit City</Button>
//                           <Button variant="contained" color="secondary" href={generateCityMapperLink(city.id)} target="_blank" style={{ minWidth: 100 ,textTransform: 'none'}}>City Mapper</Button>
//                           <Button variant="contained" color="primary" style={{ marginLeft: 7 ,textTransform: 'none'}} onClick={() => handleShowCityComponents(city.id)}>
//                             City Components
//                           </Button>
//                           {/* <Box display="inline-block" ml={1} bgcolor="red" borderRadius="40%" style={{ verticalAlign: 'middle', lineHeight: 0 }}>
//                             <MdDelete style={{ cursor: 'pointer', color: 'white', fontSize: 30, padding: '5px' }} onClick={() => handleDeleteCity(city.id)} />
//                           </Box> */}
//                           <Button variant="contained" color="error" style={{ marginLeft: 8 ,textTransform: 'none'}} startIcon={<DeleteIcon />} onClick={() => handleDeleteCity(city.id)}>Delete City</Button>
//                         </TableCell>
//                       </TableRow>
//                     ))
//                   )}
//                 </TableBody>
//               </Table>
//             </TableContainer>
//           )}
//         </>
//       )}
//     </Box>
//   );
// };

// export default Cities;


import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import CircularProgress from '@mui/material/CircularProgress';
import Table from '@mui/material/Table';
import Pagination from '@mui/lab/Pagination';
import RoomComponents from './RoomComponents';
import EditCityForm from './EditCityForm';
import CityComponents from './CityComponents';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useParams } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { BASE_URL } from './constants';

const cookies = new Cookies();

const Cities = () => {
  const [cities, setCities] = useState([]);
  const [editCityId, setEditCityId] = useState(null);
  const [showCityComponents, setShowCityComponents] = useState(false);
  const [selectedCity, setSelectedCity] = useState(null);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const { cityId } = useParams();
  const itemsPerPage = 10;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const isAdminCookie = cookies.get('isAdmin');
        let endpoint;
        if (isAdminCookie.toString() === 'true') {
          endpoint = `${BASE_URL}/api/v1/city/`;
        } else {
          endpoint = `${BASE_URL}/api/v1/city-list/`;
        }
        const token = localStorage.getItem('token') || Cookies.get('token') ;
        const response = await fetch(endpoint, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (!response.ok) {
          throw new Error('Failed to fetch cities');
        }
        const data = await response.json();
        setCities(data.results);
        setTotalPages(Math.ceil(data.results.length / itemsPerPage));
        setLoading(false);
      } catch (error) {
        console.error('Error fetching cities:', error);
      }
    };

    fetchData();
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    });
  };

  const handleEditCity = (cityId) => {
    setEditCityId(cityId);
  };

  const handleCancelEdit = () => {
    setEditCityId(null);
  };

  const generateCityMapperLink = (cityId) => {
    return `${BASE_URL}/city/mapper/${cityId}`;
  };

  const handleShowCityComponents = async (cityId) => {
    try {
      const token = cookies.get('token');
      const response = await fetch(`${BASE_URL}/api/v1/city-detail/${cityId}/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        throw new Error('Failed to fetch city components');
      }
      const data = await response.json();
      console.log('CC', data);
      setSelectedCity(data);
      setShowCityComponents(true);
    } catch (error) {
      console.error('Error fetching city components:', error);
    }
  };

  const handleCloseCityComponents = () => {
    setShowCityComponents(false);
  };

  const handleDeleteCity = async (cityId) => {
    try {
      const confirmed = window.confirm(
        "Are you sure you want to delete this city? This action won't be reversible."
      );
      if (!confirmed) return;

      const token = cookies.get('token');
      const response = await fetch(`${BASE_URL}/api/v1/city/${cityId}/`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        throw new Error('Failed to delete city');
      }
      window.alert('City deleted successfully.');
      setCities(cities.filter((city) => city.id !== cityId));
    } catch (error) {
      console.error('Error deleting city:', error);
      window.alert('Failed to delete city. Please try again later.');
    }
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const stateMap = {
    draft: 'Draft',
    in_review: 'In-Review',
    published: 'Published',
  };

  const paginatedCities = cities.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  return (
    <Box>
      {showCityComponents ? (
        <CityComponents cityData={selectedCity} onClose={handleCloseCityComponents} />
      ) : (
        <>
          <Typography variant="h5" gutterBottom>
            Your Cities
          </Typography>
          {editCityId ? (
            <EditCityForm cityId={editCityId} onClose={handleCancelEdit} />
          ) : (
            <>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ fontWeight: 'bold' }}>City Thumbnail</TableCell>
                      <TableCell style={{ fontWeight: 'bold' }}>City Name</TableCell>
                      <TableCell style={{ fontWeight: 'bold' }}>Created At</TableCell>
                      <TableCell style={{ fontWeight: 'bold' }}>State</TableCell>
                      <TableCell style={{ fontWeight: 'bold' }}>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {loading ? (
                      <TableRow>
                        <TableCell colSpan={5} style={{ textAlign: 'center' }}>
                          <CircularProgress />
                        </TableCell>
                      </TableRow>
                    ) : (
                      paginatedCities.map((city) => (
                        <TableRow key={city.id}>
                          <TableCell>
                            <img src={city.cityscape} alt={city.name} style={{ width: 100 }} />
                          </TableCell>
                          <TableCell>{city.name}</TableCell>
                          <TableCell>{formatDate(city.created_at)}</TableCell>
                          <TableCell>{stateMap[city.state]}</TableCell>
                          <TableCell>
                            <Button
                              variant="contained"
                              color="primary"
                              style={{ marginRight: 7, textTransform: 'none' }}
                              startIcon={<EditIcon />}
                              onClick={() => handleEditCity(city.id)}
                            >
                              Edit City
                            </Button>
                            <Button
                              variant="contained"
                              color="secondary"
                              href={generateCityMapperLink(city.id)}
                              target="_blank"
                              style={{ minWidth: 100, textTransform: 'none' }}
                            >
                              City Mapper
                            </Button>
                            <Button
                              variant="contained"
                              color="primary"
                              style={{ marginLeft: 7, textTransform: 'none' }}
                              onClick={() => handleShowCityComponents(city.id)}
                            >
                              City Components
                            </Button>
                            <Button
                              variant="contained"
                              color="error"
                              style={{ marginLeft: 8, textTransform: 'none' }}
                              startIcon={<DeleteIcon />}
                              onClick={() => handleDeleteCity(city.id)}
                            >
                              Delete City
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <Box mt={2} display="flex" justifyContent="center">
                <Pagination
                  count={totalPages}
                  page={currentPage}
                  onChange={handlePageChange}
                  color="primary"
                />
              </Box>
            </>
          )}
        </>
      )}
    </Box>
  );
};

export default Cities;
