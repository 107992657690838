import React, { useEffect, useRef, useState } from "react";
import Drawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Cookies from "js-cookie";

const TemporaryDrawer = ({ onSeeAllClick, onSelectThumbnail }) => {
  const [images, setImages] = useState([]);
  const [snackbar, setSnackbar] = useState({
    open: false,
    severity: "error",
    message: "",
  });
  const fileInputRef = useRef(null);

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = await fetch("http://20.51.247.117:8000/app/images/");
        const data = await response.json();

        const sortedImages = data.sort((a, b) => b.id - a.id).slice(0, 4);

        setImages(
          sortedImages.map((item) => ({
            file: item.file,
            id: item.id,
          }))
        );
      } catch (error) {
        console.error("Error fetching images:", error);
        setSnackbar({
          open: true,
          severity: "error",
          message: "Failed to fetch images. Please try again later.",
        });
      }
    };

    fetchImages();
  }, []);

  const handleUploadClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append("file", file);

    const role = Cookies.get("role");
    const isAdmin = Cookies.get("isAdmin");

    if (
      role === "ccgadmin" ||
      role === "content_creator" ||
      isAdmin === "true"
    ) {
      const newImage = { file: URL.createObjectURL(file), id: Date.now() };
      setImages((prevImages) => [...prevImages, newImage]);

      onSelectThumbnail(newImage.file);

      try {
        const response = await fetch("http://20.51.247.117:8000/app/images/", {
          method: "POST",
          body: formData,
        });

        if (!response.ok) {
          console.error("Error uploading image:", response.statusText);
          setSnackbar({
            open: true,
            severity: "error",
            message: "Failed to upload image. Please try again later.",
          });
        }
      } catch (error) {
        console.error("Error uploading image:", error);
        setSnackbar({
          open: true,
          severity: "error",
          message: "Failed to upload image. Please try again later.",
        });
      }
    } else {
      setSnackbar({
        open: true,
        severity: "error",
        message: "You must be a content creator or ccgadmin to upload images.",
      });
    }
  };

  const handleImageClick = (image) => {
    onSelectThumbnail(image.file);
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <Drawer
      anchor="right"
      open={true}
      variant="permanent"
      sx={{ width: "200%", flexShrink: 0 }}
    >
      <Box
        sx={{
          width: "100%",
          textAlign: "center",
          paddingTop: "60px",
          borderBottom: "1px solid #ccc",
        }}
      >
        <h2>Thumbnail Drawer</h2>
      </Box>
      <Box sx={{height:"600px",overflowY:"auto"}}>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          flexWrap: "wrap",
          justifyContent: "center",
          padding: "8px",
        }}
      >
        {images.map((image, index) => (
          <Box
            key={index}
            disablePadding
            sx={{
              width: "250px",
              margin: "8px",
              boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.2)",
              overflow: "hidden",
            }}
          >
            <Box className="image_Div" onClick={() => handleImageClick(image)}>
              <img
                src={image.file}
                alt={`Image ${index + 1}`}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  borderRadius: "4px",
                  maxWidth: "100%",
                  maxHeight: "100%",
                }}
              />
            </Box>
          </Box>
        ))}
      </Box>
      </Box>

      <Box
        sx={{
          marginTop: "auto",
          textAlign: "center",
          borderTop: "1px solid #ccc",
          padding: "16px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Button
          sx={{
            backgroundColor: "black",
            color: "white",
            "&:hover": { backgroundColor: "#333" },
            marginRight: "10px",
            padding: "12px 20px",
            borderRadius: "6px",
            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
            textTransform: 'none',
          }}
          onClick={onSeeAllClick}
        >
          See All
        </Button>
        <Button
          sx={{
            backgroundColor: "black",
            color: "white",
            "&:hover": { backgroundColor: "#333" },
            marginLeft: "10px",
            padding: "12px 20px",
            borderRadius: "6px",
            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
            textTransform: 'none',
          }}
          onClick={handleUploadClick}
        >
          Upload
        </Button>
        <input
          type="file"
          accept=".jpeg, .jpg, .png, .gif"
          ref={fileInputRef}
          style={{ display: "none" }}
          onChange={handleFileChange}
        />

      </Box>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
        >
          {snackbar.message}
        </MuiAlert>
      </Snackbar>
    </Drawer>
  );
};

export default TemporaryDrawer;
