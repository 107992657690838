import React, { useState, useEffect } from "react";
import CreateRoomForm from "./CreateRoomForm";
import HomePage from "./HomePage";
import Rooms from "./rooms";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import Button from "@mui/material/Button";
import HomeIcon from "@mui/icons-material/Home";
import RoomIcon from "@mui/icons-material/Room";
import Cookies from "js-cookie";
import EventIcon from "@mui/icons-material/Event";
import EventDetails from "./EventDetails";
import ProfilePage from "./ProfilePage";
import { Navigate, useNavigate, useNavigation } from "react-router-dom";
import { BASE_URL } from "./constants";
import { MdLocationCity } from "react-icons/md";
import ApartmentIcon from '@mui/icons-material/Apartment';
import ListIcon from '@mui/icons-material/List';
import { People } from "@mui/icons-material";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function MiniDrawer() {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [selectedPage, setSelectedPage] = useState("homepage");
  const [anchorEl, setAnchorEl] = useState(null);
  const [userType, setUserType] = useState(null); // State to store user type
  const [isAdmin, setIsAdmin] = useState(false); // State to store admin status
  const history = useNavigate();
  const [showProfile, setShowProfile] = useState(false);

  useEffect(() => {
    // Fetch user profile data
    const fetchUserProfile = async () => {
      try {
        const token = localStorage.getItem('token') || Cookies.get('token') ;
        if (!token) {
          history("/login");
        }
        const response = await fetch(`${BASE_URL}/api/v1/user-profile/`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await response.json();
        if (response.ok) {
          // Extract user type and admin status from the fetched data
          const user = data.results[0];
          console.log("type",user.user_type);
          setUserType(user.user_type);
          setIsAdmin(user.is_admin);
        } else {
          throw new Error("Failed to fetch user profile data");
        }
      } catch (error) {
        console.error("Error fetching user profile data:", error);
      }
    };

    fetchUserProfile();
  }, []);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handlePageChange = (page) => {
    setSelectedPage(page);
  };
  const handleProfilePage = ()=>{
    console.log("createroomform")
     history('/myprofile');
  }
  const handleCreateCity = () => {
    console.log("createCity");
    history("/createCity");
  };
  const handleCreateRoom = () => {
    console.log("createroomform");
    history("/createRoom");
  };
  const handleRoom = () => {
    console.log("rooms");
    history("/rooms");
  };
  const handleCity = () => {
    console.log("rooms");
    history("/cities");
  };
  const handleUser = () => {
    console.log("users");
    history("/users");
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleProfile = () => {
    setShowProfile(true); // Set the state to show the profile page
    handleMenuClose();
  };

  // const handleLogout = () => {
  //   // Close the menu (if needed)
  //   // Clear the token cookie
  //   Cookies.remove("token");
  //   window.location.href = "/login";
  //   // Redirect to the login page
  //   // history.push('/login');
  //   handleMenuClose();
  // };

  const handleLogout = () => {
    // Close the menu (if needed)
    handleMenuClose();
    localStorage.removeItem('token');

    // Remove the token cookie from the current domain
    Cookies.remove("token", { path: '/' });

    // Remove the token cookie from the top domain
    const domain = window.location.hostname.split('.').slice(-2).join('.'); // Get the top domain
    Cookies.remove("token", { path: '/', domain: domain });

    // Redirect to the login page
    window.location.href = "/login";
  };

  const handleEvent = ()=>{
    history('/events');
  }

  const renderPage = () => {
    switch (selectedPage) {
      case "inbox":
        return (
          <div>
            <Typography variant="h6" gutterBottom>
              Inbox Page Content...
            </Typography>
          </div>
        );
      // case 'createRoom':
      //   return <CreateRoomForm />;
      case "homepage":
        return <HomePage />;
      // case 'rooms':
      //   return <Rooms/>;
      case "event":
        return <EventDetails />;
      case "myprofile":
        return <ProfilePage />;
      // case "city":
      //   return <ProfilePage />;
      case "reviewRooms":
        // Implement logic for the reviewRooms page
        return <EventDetails />;
      // break;
      case "reviewEvents":
        // Implement logic for the reviewEvents page
        break;
      case "users":
        // Implement logic for the users page
        break;
      default:
        return null;
    }
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed" open={open} sx={{ backgroundColor: "black" }}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 2,
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            Stem-City CMS
          </Typography>
          <Button
            color="inherit"
            onClick={() => window.open("https://stemcity3d.com/", "_blank")}
            sx={{ marginLeft: "775px" }}
          >
            Go to Stem City Main
          </Button>
          <IconButton
            color="inherit"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenuOpen}
            sx={{ marginLeft: "auto" }}
          >
            <AccountCircleIcon />
            <ArrowDropDownIcon />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "bottom", // Align the menu to the bottom of the icon
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            <MenuItem onClick={() => handleProfilePage()}>
              My Profile
            </MenuItem>
            <MenuItem onClick={handleLogout}>Logout</MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
  <DrawerHeader>
    <IconButton onClick={handleDrawerClose}>
      {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
    </IconButton>
  </DrawerHeader>
  <Divider />
  <List>
    <ListItem
      key="homepage"
      disablePadding
      onClick={() => handlePageChange("homepage")}
    >
      <ListItemButton>
        <ListItemIcon>
          <HomeIcon />
        </ListItemIcon>
        <ListItemText primary="Home Page" />
      </ListItemButton>
    </ListItem>
    <ListItem
      key="myCities"
      disablePadding
      onClick={() => handleCity()} // Update the key to match the component name
    >
      <ListItemButton>
        <ListItemIcon>
          <ListIcon />
        </ListItemIcon>
        <ListItemText primary="My Cities" />
      </ListItemButton>
    </ListItem>
    {userType === "ccgadmin" && (
      <ListItem
        key="createCity"
        disablePadding
        onClick={() => handleCreateCity()}
      >
        <ListItemButton>
          <ListItemIcon>
            {/* Icon for City */}
            <ApartmentIcon />
          </ListItemIcon>
          <ListItemText primary="Create City" />
        </ListItemButton>
      </ListItem>
    )}
    <ListItem
      key="myRooms"
      disablePadding
      onClick={() => handleRoom()} // Update the key to match the component name
    >
      <ListItemButton>
        <ListItemIcon>
          <RoomIcon />
        </ListItemIcon>
        <ListItemText primary="My Rooms" />
      </ListItemButton>
    </ListItem>
    <ListItem
      key="createRoom"
      disablePadding
      onClick={() => handleCreateRoom()}
    >
      <ListItemButton>
        <ListItemIcon>
          <AddCircleIcon />
        </ListItemIcon>
        <ListItemText primary="Create a Room" />
      </ListItemButton>
    </ListItem>
    <ListItem
      key="event"
      disablePadding 
      onClick={() => handleEvent()}
    >
      <ListItemButton>
        <ListItemIcon>
          <EventIcon />
        </ListItemIcon>
        <ListItemText primary="Event" />
      </ListItemButton>
    </ListItem>   
    {userType === "ccgadmin" && (
     
    <ListItem
      key="user"
      disablePadding 
      onClick={() => handleUser()}
    >
      <ListItemButton>
        <ListItemIcon>
          <People />
        </ListItemIcon>
        <ListItemText primary="User" />
      </ListItemButton>
    </ListItem>   
    )}     
  </List>
  <Divider />
</Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
        {renderPage()}
      </Box>
    </Box>
  );
}
