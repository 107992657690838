
import React, { useState, useRef, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { MenuItem, Select } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CircularProgress from "@mui/material/CircularProgress";
import Cookies from "js-cookie";
import { BASE_URL } from "./constants";

// Utility function to convert image URL to a Blob and get the image name
const urlToBlob = async (url) => {
  const response = await fetch(url);
  const blob = await response.blob();
  const urlParts = url.split("/");
  const fileName = urlParts[urlParts.length - 1].split("?")[0]; // Remove query parameters from file name
  return { blob, fileName };
};

const EditUser = ({ event, onClose, updateUserList }) => {
  const inputFileRef = useRef(null);

  console.log("event data to edit", event);
  const [eventData, setEventData] = useState({
    name: event.name,
    email: event.email,
    is_active: event.is_active,
    profile_photo: null, // Initialize as null
    phone_number: event.phone_number,
    user_type: event.user_type,
  });
  const [previewImage, setPreviewImage] = useState(event.profile_photo);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({
    name: false,
    email: false,
    phone_number: false,
  });

  // Fetch the profile photo as a Blob if no new photo is uploaded
  useEffect(() => {
    async function fetchProfilePhoto() {
      if (!inputFileRef.current?.files?.length) {
        if (event.profile_photo) {
          const { blob, fileName } = await urlToBlob(event.profile_photo);
          setEventData((prevData) => ({
            ...prevData,
            profile_photo: new File([blob], fileName, { type: blob.type }),
          }));
        }
      }
    }
    fetchProfilePhoto();
  }, [event.profile_photo]);

  const handleCameraIconClick = () => {
    inputFileRef.current.click();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "phone_number") {
      if (!/^\d{0,10}$/.test(value)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          phone_number: "Phone number must be exactly 10 digits",
        }));
      } else {
        setEventData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
        setErrors((prevErrors) => ({
          ...prevErrors,
          phone_number: false,
        }));
      }
    } else if (name === "email") {
      if (!/\S+@\S+\.\S+/.test(value) && value.trim() !== "") {
        setErrors((prevErrors) => ({
          ...prevErrors,
          email: "Please enter a valid email address",
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          email: false,
        }));
      }
      setEventData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    } else {
      setEventData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: false,
      }));
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setEventData((prevData) => ({
      ...prevData,
      profile_photo: file,
    }));
    const reader = new FileReader();
    reader.onload = (e) => {
      setPreviewImage(e.target.result);
    };
    reader.readAsDataURL(file);
  };

  const handleSubmit = async () => {
    if (errors.email) {
      return;
    }

    if (!eventData.name || !eventData.email) {
      setErrors({
        ...errors,
        name: !eventData.name,
        email: !eventData.email,
      });
      return;
    }

    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("name", eventData.name);
      formData.append("email", eventData.email);
      formData.append("phone_number", eventData.phone_number);
      // Append the profile photo, which is either a Blob from the URL or a new file
      if (eventData.profile_photo) {
        formData.append("profile_photo", eventData.profile_photo);
      }
      formData.append("user_type", eventData.user_type);

      const token = localStorage.getItem("token") || Cookies.get("token");
      const response = await fetch(`${BASE_URL}/api/v1/users-list/${event.id}/`, {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error("Failed to update user");
      }

      toast.success("User updated successfully");
      updateUserList();
      onClose();
    } catch (error) {
      console.error("Error updating user:", error);
      toast.error("Failed to update user");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={true} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6">Edit User</Typography>
          <Button
            onClick={onClose}
            startIcon={<CloseIcon />}
            style={{
              backgroundColor: "#fdd835",
              color: "#424242",
              fontWeight: "bold",
              boxShadow: "none",
            }}
          >
            Close
          </Button>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography
              variant="subtitle1"
              style={{
                marginBottom: "8px",
                color: "#424242",
                fontWeight: "bold",
              }}
            >
              Name
            </Typography>
            <TextField
              name="name"
              value={eventData.name}
              onChange={handleChange}
              fullWidth
              variant="outlined"
              error={errors.name}
              helperText={errors.name && "Please enter your name"}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="subtitle1"
              style={{
                marginBottom: "8px",
                color: "#424242",
                fontWeight: "bold",
              }}
            >
              Email
            </Typography>
            <TextField
              name="email"
              value={eventData.email}
              onChange={handleChange}
              fullWidth
              variant="outlined"
              error={errors.email}
              helperText={errors.email && "Email must be in proper format"}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="subtitle1"
              style={{
                marginBottom: "8px",
                color: "#424242",
                fontWeight: "bold",
              }}
            >
              Phone Number
            </Typography>
            <TextField
              name="phone_number"
              value={eventData.phone_number}
              onChange={handleChange}
              fullWidth
              variant="outlined"
              type="number"
              error={errors.phone_number}
              helperText={errors.phone_number && "Phone number must be exactly 10 digits"}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="subtitle1"
              style={{
                marginBottom: "8px",
                color: "#424242",
                fontWeight: "bold",
              }}
            >
              User Type
            </Typography>
            <Select
              name="user_type"
              value={eventData.user_type}
              onChange={handleChange}
              fullWidth
              variant="outlined"
            >
              <MenuItem value="ccgadmin">CCG Admin</MenuItem>
              <MenuItem value="user">User</MenuItem>
              <MenuItem value="content_creator">CCG Content Creator</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="subtitle1"
              style={{
                marginBottom: "8px",
                color: "#424242",
                fontWeight: "bold",
              }}
            >
              Profile Photo
            </Typography>
            <input
              type="file"
              accept="image/*"
              ref={inputFileRef}
              onClick={handleCameraIconClick}
              onChange={handleFileChange}
              style={{ display: 'none' }}
            />
            <Button onClick={handleCameraIconClick}>
              Upload Photo
            </Button>
            {previewImage && (
              <img
                src={previewImage}
                alt="Preview"
                style={{ maxWidth: "100px", maxHeight: "100px" }}
              />
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={loading}
          style={{
            borderRadius: "20px",
            fontWeight: "bold",
            boxShadow: "none",
            textTransform: "none",
          }}
        >
          {loading && <CircularProgress size={24} style={{ position: 'absolute', top: '50%', left: '50%', marginTop: -12, marginLeft: -12, color: 'white' }} />}
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditUser;
