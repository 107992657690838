import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Cookies from "js-cookie";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Pagination from "@mui/material/Pagination";
import { BASE_URL } from "./constants";
import EditUser from "./EditUser";
import AddUser from "./AddUser";
import { Switch } from "@mui/material";

// Function to fetch event details from API

const fetchUserList = async () => {
  try {
    const token = localStorage.getItem("token") || Cookies.get("token");
    let endpoint;
    endpoint = `${BASE_URL}/api/v1/users-list/`;
    const response = await fetch(endpoint, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log("api response", response);

    if (!response.ok) {
      throw new Error("Failed to fetch event details");
    }
    const eventData = await response.json();
    return eventData.results;
  } catch (error) {
    console.error("Error fetching event details:", error);
    return [];
  }
};

const UserCURD = () => {
  const [eventData, setEventData] = useState([]);
  const [showEdit, setShowEdit] = useState(false);
  const [showAdd, setShowAdd] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  useEffect(() => {
    fetchUserList().then((data) => {
      setEventData(data);
    });
  }, []);

  const handleEdit = (event) => {
    setSelectedEvent(event);
    setShowEdit(true);
  };

  const handleDelete = async (eventName, eventId) => {
    if (
      window.confirm(
        "Are you sure you want to delete the event? This action cannot be reversed."
      )
    ) {
      try {
        const token = localStorage.getItem("token") || Cookies.get("token");
        const response = await fetch(
          `${BASE_URL}/api/v1/users-list/${eventId}/`,
          {
            method: "DELETE",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (!response.ok) {
          throw new Error("Failed to delete event");
        }
        setEventData((prevEventData) =>
          prevEventData.filter((event) => event.id !== eventId)
        );

        // Fetch the updated user list after deletion
        const updatedUserList = await fetchUserList();
        setEventData(updatedUserList);
        setShowSnackbar(true);
      } catch (error) {
        toast.error("Error deleting event");
        console.error("Error deleting event:", error);
      }
    }
  };

  const handleAddEvent = () => {
    setShowAdd(true);
  };

  const handleCloseSnackbar = () => {
    setShowSnackbar(false);
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const paginatedEvents = eventData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );
  const totalPages = Math.ceil(eventData.length / itemsPerPage);

  const handleStatusToggle = async (userId, currentStatus) => {
    try {
      const token = localStorage.getItem("token") || Cookies.get("token");
      const formData = new FormData();
      formData.append("is_active", currentStatus ? "False" : "True");
      const response = await fetch(`${BASE_URL}/api/v1/users-list/${userId}/`, {
        method: "PATCH",
        headers: {
          // "Content-Type": "application/json", // Set Content-Type header
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });
      if (!response.ok) {
        throw new Error("Failed to update user status");
      }
      // Update the local state with the updated status
      setEventData((prevEventData) =>
        prevEventData.map((user) =>
          user.id === userId ? { ...user, is_active: !currentStatus } : user
        )
      );
      // Show success message
      toast.success("User status updated successfully");
    } catch (error) {
      toast.error("Error updating user status");
      console.error("Error updating user status:", error);
    }
  };

  const fetchAndUpdateUserList = () => {
    fetchUserList().then((data) => {
      setEventData(data);
    });
  };

  const getStateDisplayName = (state) => {
    switch (state) {
      case 'user':
        return 'User';
      case 'content_creator':
        return 'Content-Creator';
      case 'ccgadmin':
        return 'CCG-Admin';
      default:
        return state;
    }
  };

  return (
    <Box>
      <Typography variant="h5" gutterBottom>
        User List
      </Typography>
      <Button
        variant="contained"
        color="primary"
        sx={{ mb: 2, float: "right", textTransform: "none" }}
        onClick={handleAddEvent}
      >
        Add User
      </Button>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ fontWeight: "bold" }}> Name</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>E-mail</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Phone number</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>User Type</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>
                Active/Inactive
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedEvents.map((event) => (
              <TableRow key={event.name}>
                <TableCell>{event.name || "not available"}</TableCell>

                <TableCell>{event.email}</TableCell>
                <TableCell>{event.phone_number}</TableCell>
                <TableCell>{getStateDisplayName(event.user_type)}</TableCell>
                <TableCell>
                  <Switch
                    checked={event.is_active}
                    onChange={() =>
                      handleStatusToggle(event.id, event.is_active)
                    }
                    color="primary"
                  />
                </TableCell>
                <TableCell>
                  <Box sx={{ display: "flex", flexDirection: "row" }}>
                    <Button
                      variant="outlined"
                      startIcon={<EditIcon />}
                      onClick={() => handleEdit(event)}
                      sx={{ textTransform: "none" }}
                    >
                      Edit
                    </Button>
                    <Button
                      variant="outlined"
                      startIcon={<DeleteIcon />}
                      onClick={() => handleDelete(event.name, event.id)}
                      sx={{ ml: 1, textTransform: "none" }}
                    >
                      Delete
                    </Button>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        count={totalPages}
        page={currentPage}
        onChange={handlePageChange}
        sx={{ mt: 2, display: "flex", justifyContent: "center" }}
      />
      {showEdit && (
        <EditUser
          event={selectedEvent}
          onClose={() => setShowEdit(false)}
          updateUserList={fetchAndUpdateUserList}
        />
      )}
      {showAdd && (
        <AddUser
          onClose={() => setShowAdd(false)}
          updateUserList={fetchAndUpdateUserList}
        />
      )}
      <Snackbar
        open={showSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleCloseSnackbar}
          severity="success"
        >
          User deleted successfully
        </MuiAlert>
      </Snackbar>
    </Box>
  );
};

export default UserCURD;
