
import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Toolbar from '@mui/material/Toolbar';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { BASE_URL } from './constants';

const ReviewCities = ({ onClose }) => {
  const [rooms, setRooms] = useState([]);
  const [selectedRoom, setSelectedRoom] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [roomDetails, setRoomDetails] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = document.cookie.split('; ').find(row => row.startsWith('token')).split('=')[1];
        const response = await fetch(`${BASE_URL}/api/v1/city/`, {
          headers: {
            'Authorization': `Token ${token}`
          }
        });
        if (!response.ok) {
          throw new Error('Failed to fetch cities');
        }
        const roomData = await response.json();
        console.log("rooms",roomData.results);
        setRooms(roomData.results);
      } catch (error) {
        console.error('Error fetching cities:', error);
      }
    };

    fetchData();
  }, []);

  const getStateDisplayName = (state) => {
    switch (state) {
      case 'in_review':
        return 'In Review';
      case 'draft':
        return 'Draft';
      case 'published':
        return 'Published';
      default:
        return state;
    }
  };

  const handleGoBack = () => {
    onClose();
  };


  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedRoom(null);
  };

  const handlePublishRoom = async (roomId) => {
    try {
            const token = document.cookie.split('; ').find(row => row.startsWith('token')).split('=')[1];
            const roomToUpdate = rooms.find(room => room.id === roomId);
            if (roomToUpdate.state === 'published') {
              window.alert('City already published');
              return;
            }
            const formData = new FormData();
            formData.append('state', 'published');
            const response = await fetch(`${BASE_URL}/api/v1/city/${roomId}/`, {
              method: 'PATCH',
              headers: {
                'Authorization': `Token ${token}`
              },
              body: formData
            });
            if (!response.ok) {
              throw new Error('Failed to update city state');
            }
            // Refresh rooms after successful state change
            const updatedRooms = [...rooms];
            const roomIndex = updatedRooms.findIndex(room => room.id === roomId);
            if (roomIndex !== -1) {
              updatedRooms[roomIndex].state = 'published';
              setRooms(updatedRooms);
            }
            window.alert('City published successfully');
          } catch (error) {
            console.error('Error updating city state:', error);
            window.alert('Failed to publish city');
          }
    };
  const handleSeeRoom = (roomId) => {
    window.open(`${BASE_URL}/city/review/${roomId}`, '_blank');
  };
  
  const handleSeeCity = (roomId) => {
    window.open(`${BASE_URL}/city/look/${roomId}`, '_blank');
  };
  
  return (
    <div style={{ padding: '20px', margin: 'auto' }}>
      <Toolbar style={{ display: 'flex', justifyContent: 'center' }}>
        <Typography variant="h6" component="div" style={{ fontWeight: 'bold' }}>
          Manage Cities
        </Typography>
        <Button onClick={onClose} startIcon={<ArrowBackIcon />} sx={{ bgcolor: 'yellow', color: '#000000' ,left:'300px' }}>
          Go Back
        </Button>
      </Toolbar>
      <Typography variant="body1" gutterBottom>
        Here are the Cities:
      </Typography>
      <TableContainer component={Paper}>
        <Table aria-label="review rooms table">
          <TableHead>
            <TableRow>
              <TableCell style={{ fontWeight: 'bold' }}>City Name</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>City Manager</TableCell>
              {/* <TableCell style={{ fontWeight: 'bold' }}>City Owner</TableCell> */}
              <TableCell style={{ fontWeight: 'bold' }}>City State</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rooms.map((room) => (
              <TableRow key={room.id}>
                <TableCell>{room.name}</TableCell>
                <TableCell>{room.city_manager_email || 'No Manager'}</TableCell>
                {/* <TableCell>{room.added_by_email}</TableCell> */}
                <TableCell>{getStateDisplayName(room.state)}</TableCell>
                <TableCell>
                  <Button variant="contained" color="primary" onClick={() => handlePublishRoom(room.id)}>
                    Publish City
                  </Button>
                  <Button variant="contained" color="primary" style={{ marginLeft: 8 }} onClick={() => handleSeeRoom(room.id)}>
                    Review Hotspots
                  </Button>
                  <Button variant="contained" color="primary" style={{ marginLeft: 8 }} onClick={() => handleSeeCity(room.id)}>
                    Review City
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={modalOpen} onClose={handleCloseModal} fullWidth maxWidth="sm">
        <DialogTitle>
          <Typography variant="h6">City Details</Typography>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="subtitle1" style={{ marginBottom: '8px', color: '#424242', fontWeight: 'bold' }}>Room Name</Typography>
              <TextField
                fullWidth
                variant="outlined"
                value={roomDetails?.name || ''}
                InputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1" style={{ marginBottom: '8px', color: '#424242', fontWeight: 'bold' }}>Description</Typography>
              <TextField
                fullWidth
                multiline
                rows={4}
                variant="outlined"
                value={roomDetails?.stripped_description || ''}
                InputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1" style={{ marginBottom: '8px', color: '#424242', fontWeight: 'bold' }}>City Name</Typography>
              <TextField
                fullWidth
                variant="outlined"
                value={roomDetails?.city_name || ''}
                InputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1" style={{ marginBottom: '8px', color: '#424242', fontWeight: 'bold' }}>Added By</Typography>
              <TextField
                fullWidth
                variant="outlined"
                value={roomDetails?.added_by_email || ''}
                InputProps={{ readOnly: true }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ReviewCities;
