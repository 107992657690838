import React, { useEffect, useState } from 'react';
import { TextField, Button, Grid, Paper, Typography, Modal, Box, CircularProgress } from '@mui/material';
import { BASE_URL } from './constants';
import { BrowserRouter as Router, useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import logo from './assets/logo.png';
import { ContentPasteSearchOutlined } from '@mui/icons-material';

const cookies = new Cookies();

const LoginForm = ({ onLogin, backgroundImageUrl, onSignUp }) => {
  const history = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailError, setEmailError] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const token = cookies.get("token");
    if (token) {
      history("/dashboard");
    }

  }, [])
  const [errors, setErrors] = useState({
    email: "",
    password: "",
    apiError: "",
  });

  const validateForm = () => {
    let valid = true;
    const newErrors = {};

    if (!email.trim()) {
      newErrors.email = "Email is required";
      valid = false;
    } else if (!validateEmail(email)) {
      newErrors.email = "Invalid email format";
      valid = false;
    }

    if (!password.trim()) {
      newErrors.password = "Password is required";
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };


  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const handleLogin = async () => {

    if (validateForm()) {
      setLoading(true);
      try {
        const response = await fetch(`${BASE_URL}/account/login/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ email, password }),
        });

        if (!response.ok) {
          throw new Error('Failed to login. Please check your credentials and try again.'); // Handle error response
        }


        // Extract JWT token from response
        const responseData = await response.json();


        const { token, data: { user_type, is_admin } } = responseData;

        // Set token in cookies
        // cookies.set('token', token, { path: '/', domain: '.localhost', secure: false, sameSite: 'strict' });
        cookies.set('token', token, { path: '/', domain: '.stemcity3d.com', secure: false, sameSite: 'strict' });
        cookies.set('role', user_type, { path: '/', secure: false, sameSite: 'strict' });
        cookies.set('isAdmin', is_admin, { path: '/', secure: false, sameSite: 'strict' });
        // Store user role and admin status in local storage
        localStorage.setItem('role', user_type);
        localStorage.setItem('isAdmin', is_admin);
        localStorage.setItem('token', token)
        // Redirect user to dashboard
        window.location.href = '/dashboard';
      } catch (error) {
        console.error('Error during login:', error);
        // Display error message to the user using toast
        toast.error(error.message);
      } finally {
        setLoading(false); // this is new line
      }
    }
  };

  const handleSendResetLink = async () => {
    if (!email) {
      setEmailError('Please enter email');
      toast.error('Please enter email');
      return;
    }
  
    try {
      // Make an API call to send the reset link
      const response = await fetch(`${BASE_URL}/account/send-reset-password-email/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });
  
      if (response.ok) {
        // Reset link sent successfully
        toast.success('Reset link sent successfully');
      } else {
        // Error handling for failed API call
        const responseData = await response.json();
        toast.error(responseData.message || 'Failed to send reset link');
      }
    } catch (error) {
      console.error('Error sending reset link:', error);
      toast.error('Failed to send reset link');
    }
  
    handleCloseModal();
  };
  

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setEmail('');
    setPassword('');
    setEmailError('');
  };

  return (
    <Grid container justifyContent="center" alignItems="center" style={{ height: '100vh' }}>
      <Grid item xs={10} sm={8} md={6} lg={4}>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <img src={logo} alt="Logo" style={{ width: '30%', marginBottom: 20 }} />
        </div>
        <Typography variant="h5" align="center" gutterBottom style={{ fontFamily: 'Montserrat, sans-serif', fontWeight: 700 }}>
          Content Management System
        </Typography>
        <Paper
          elevation={3}
          style={{
            padding: 20,
            height: 400,
            backgroundImage: `url(${backgroundImageUrl})`,
            backgroundSize: 'cover',
            backgroundColor: 'white',
            position: 'relative', // Add position relative for absolute positioning of links
          }}
        >
          <Typography variant="h5" align="center" gutterBottom>
            Login
          </Typography>

          <TextField
            label="Email"
            fullWidth
            margin="normal"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            error={!!errors.email}
            helperText={errors.email}
          />

          <TextField
            label="Password"
            type="password"
            fullWidth
            margin="normal" // Use the shorthand notation for margin
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            style={{ marginTop: 30 }} // Additional marginTop for the password field
            error={!!errors.password}
            helperText={errors.password}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleLogin();
              }
            }}
          />

          <Button
            variant="contained"
            style={{ backgroundColor: 'black', color: 'white', marginTop: 30 }}
            fullWidth
            disabled = {loading}
            onClick={handleLogin}
          >
             {loading && <CircularProgress size={24} style={{ position: 'absolute', top: '50%', left: '50%', marginTop: -12, marginLeft: -12, color: 'white' }} />}
            Login
          </Button>
          
          {/* {loading && ( // this is new line
            <Box display="flex" justifyContent="center" mt={2}> 
              <CircularProgress /> 
            </Box> // this is new line
          )} */}

          <Typography variant="body2" style={{ marginTop: 15 }}>
            <Button
              color="inherit"
              onClick={handleOpenModal}
            >
              Forgot Password?
            </Button>
          </Typography>

          <Box mt={2} display="flex" justifyContent="space-between">
            <Typography variant="body2">
              Don't have an account?
              <Button
                color="inherit"
                onClick={() => window.location.href = '/signup'}
              >
                Register
              </Button>
            </Typography>

          </Box>
        </Paper>
      </Grid>

      {/* Forgot Password Modal */}
      <Modal open={openModal} onClose={handleCloseModal}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 300,
            bgcolor: 'white',
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography variant="h5" align="center" gutterBottom>
            Forgot Password
          </Typography>
          <TextField
            label="Email"
            fullWidth
            margin="normal"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            error={!!emailError}
            helperText={emailError}
          />
          <Button
            variant="contained"
            style={{ backgroundColor: 'black', color: 'white', marginTop: 15 }}
            fullWidth
            onClick={handleSendResetLink}
          >
            Send Reset Link
          </Button>
        </Box>
      </Modal>

      {/* Toast Container */}
      <ToastContainer />
    </Grid>
  );
};

export default LoginForm;
