import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import Cookies from 'js-cookie';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Modal from '@mui/material/Modal';
import { BASE_URL } from './constants';
import { Editor } from '@tinymce/tinymce-react';
import Switch from '@mui/material/Switch'; 
import FormControl from '@mui/material/FormControl'; // Import FormControl
import Select from '@mui/material/Select'; // Import Select
import MenuItem from '@mui/material/MenuItem'; // Import MenuItem
import CircularProgress from '@mui/material/CircularProgress'; 
import { Checkbox, FormControlLabel } from '@mui/material';

const EditCityForm = ({ onClose, cityId }) => {
  const [cityData, setCityData] = useState({
    name: '',
    cityscape: '',
    flyin_graphic: null,
    status: 'active',
    state: 'draft',
    city_manager: ''
  });
  const [events, setEvents] = useState([]);
  const [tinyEditors, setTinyEditors] = useState([]);
  const [snackbar, setSnackbar] = useState({
    open: false,
    severity: 'success',
    message: ''
  });

  const [modifiedFields, setModifiedFields] = useState({});
  const [tinymceData, setTinyMCEData] = useState([]);
  const [selectedEditor, setSelectedEditor] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [updatedName, setUpdatedName] = useState('');
  const [updatedContent, setUpdatedContent] = useState('');
  const [isActive, setIsActive] = useState(false);
  const [cityManagers, setCityManagers] = useState([]); // State to store city managers
  const [selectedCityManager, setSelectedCityManager] = useState('');
  const [uploadedSupportAudio, setUploadedSupportAudio] = useState(null);
  const [loading, setLoading] = useState(false);
  const [clearCityscape, setClearCityscape] = useState(false);
  const [clearFlyinGraphic, setClearFlyinGraphic] = useState(false);
  const [clearLoopGraphic, setClearLoopGraphic] = useState(false);
  const [clearAudio, setClearAudio] = useState(false);

  useEffect(() => {
    const fetchCityData = async () => {
      try {
        const token = localStorage.getItem('token') || Cookies.get('token') ;
        const response = await fetch(`${BASE_URL}/api/v1/city/${cityId}/`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await response.json();
        if (!response.ok) {
          throw new Error('Failed to fetch city data');
        }
        setCityData(data);
        setIsActive(data.is_active);
      } catch (error) {
        console.error('Error fetching city data:', error);
      }
    };

    fetchCityData();
  }, [cityId]);

  const handleUpdateCity = async () => {
    try {
      console.log("Active",isActive);
      const token = Cookies.get('token');
      const formData = new FormData();

      // Append modified fields to formData
      for (const key in modifiedFields) {
        formData.append(key, modifiedFields[key]);
      }
      
      formData.append('state', 'draft');
      
      const response = await fetch(`${BASE_URL}api/v1/cities/${cityId}/`, {
        method: 'PATCH',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Failed to update city');
      }

      // Show success Snackbar
      setSnackbar({ open: true, severity: 'success', message: 'City updated successfully. Reload page to see changes' });
    } catch (error) {
      console.error('Error updating city:', error);
      // Show error Snackbar
      setSnackbar({ open: true, severity: 'error', message: 'Failed to update city' });
    }
  };

  const handleClearCityscapeChange = (event) => {
    setClearCityscape(event.target.checked);
    if (event.target.checked) {
        setCityData((prevData) => ({ ...prevData, cityscape: null }));
        setModifiedFields((prevFields) => ({ ...prevFields, cityscape: '' }));
    }
  };

    const handleClearFlyinGraphicChange = (event) => {
        setClearFlyinGraphic(event.target.checked);
        if (event.target.checked) {
            setCityData((prevData) => ({ ...prevData, flyin_graphic: null }));
            setModifiedFields((prevFields) => ({ ...prevFields, flyin_graphic: '' }));
        }
    };

    const handleClearLoopGraphicChange = (event) => {
        setClearLoopGraphic(event.target.checked);
        if (event.target.checked) {
            setCityData((prevData) => ({ ...prevData, loop_graphic: null }));
            setModifiedFields((prevFields) => ({ ...prevFields, loop_graphic: '' }));
        }
    };
  const handleClearSupportAudioChange = (event) => {
      setClearAudio(event.target.checked);
      if (event.target.checked) {
          setCityData((prevData) => ({ ...prevData, support_audio: null }));
          setModifiedFields((prevFields) => ({ ...prevFields, support_audio: '' }));
      }
  };


  const handleEditContent = (editor) => {
    setSelectedEditor(editor);
    setUpdatedContent(editor.content);
    setUpdatedName(editor.name);
    setModalOpen(true);
  };

  const handleDeleteContent = async (id) => {
    try {
      console.log("iddd",id)
      const token = Cookies.get('token');
      const response = await fetch(`${BASE_URL}/api/v1/city-tiny-editor/${id}/`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error('Failed to delete editor');
      }

      // Show success Snackbar
      setSnackbar({ open: true, severity: 'success', message: 'Editor deleted successfully' });
      setModalOpen(false);
    } catch (error) {
      console.error('Error deleting editor:', error);
      // Show error Snackbar
      setSnackbar({ open: true, severity: 'error', message: 'Failed to delete editor' });
    }
  };

  const handleDeleteRoom = async () => {
    const confirmed = window.confirm("Are you sure you want to delete this city? This action cannot be reversed.");
    if (!confirmed) return;

    try {
      const token = Cookies.get('token');
      const response = await fetch(`${BASE_URL}/api/v1/city/${cityId}/`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error('Failed to delete city');
      }

      // Show success Snackbar
      setSnackbar({ open: true, severity: 'success', message: 'city deleted successfully' });
    } catch (error) {
      console.error('Error deleting city:', error);
      // Show error Snackbar
      setSnackbar({ open: true, severity: 'error', message: 'Failed to delete city' });
    }
  };


  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleUpdateContent = async () => {
    try {
      const token = Cookies.get('token');
      const formData = new FormData();
      formData.append('name', updatedName);
      formData.append('content', updatedContent);
      
      const response = await fetch(`${BASE_URL}/api/v1/city-tiny-editor/${selectedEditor.id}/`, {
        method: 'PATCH',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Failed to update city content');
      }

      setSnackbar({ open: true, severity: 'success', message: 'City content updated successfully' });
      setModalOpen(false);
    } catch (error) {
      console.error('Error updating city content:', error);
      setSnackbar({ open: true, severity: 'error', message: 'Failed to update city content' });
    }
  };

  const handleDeleteCity = async () => {
    const confirmed = window.confirm("Are you sure you want to delete this city? This action cannot be reversed.");
    if (!confirmed) return;

    try {
      const token = Cookies.get('token');
      const response = await fetch(`${BASE_URL}/api/v1/city/${cityId}/`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error('Failed to delete city');
      }

      // Show success Snackbar
      setSnackbar({ open: true, severity: 'success', message: 'City deleted successfully' });
    } catch (error) {
      console.error('Error deleting city:', error);
      // Show error Snackbar
      setSnackbar({ open: true, severity: 'error', message: 'Failed to delete city' });
    }
  };

  const handleTinyMCEChange = (content, name, id) => {
    // Handle editor change
    console.log("content",content);
    console.log("id",id);
    console.log("name",name);
    setTinyMCEData(prevData => [
      ...prevData,
      { id: id, content: content, name: name }
    ]);

    // Call your API here if needed
  };

  const handleChange = (e) => {
    const { name, value, files } = e.target;
  
    if (name === 'activeInactiveSwitch') {
      setIsActive(value); // Update isActive state
      setCityData((prevCityData) => ({
        ...prevCityData,
        is_active: value, // Update cityData.is_active
      }));
    } else {
      if (files && files[0]) {
        const file = files[0];
        const reader = new FileReader();
  
        reader.onload = (event) => {
          setCityData((prevCityData) => ({
            ...prevCityData,
            [name]: event.target.result,
          }));
        };
  
        reader.readAsDataURL(file);
  
        setModifiedFields((prevModifiedFields) => ({
          ...prevModifiedFields,
          [name]: file,
        }));
      } else {
        // Handle changes in other fields
        let newValue = value;
        if (name === 'description') {
          newValue = value.replace(/<[^>]+>/g, '');
        }
        setCityData((prevCityData) => ({
          ...prevCityData,
          [name]: newValue,
          stripped_description: name === 'description' ? newValue : prevCityData.stripped_description,
        }));
        setModifiedFields((prevModifiedFields) => ({
          ...prevModifiedFields,
          [name]: value,
        }));
      }
    }
  };
  

  useEffect(() => {
    const fetchCityManagers = async () => {
      try {
        const response = await fetch(`${BASE_URL}/city/citymanager/`);
        const data = await response.json();
        if (!response.ok) {
          throw new Error('Failed to fetch city managers');
        }
        setCityManagers(data);
      } catch (error) {
        console.error('Error fetching city managers:', error);
      }
    };

    fetchCityManagers();
  }, []);

  const handleCityManagerChange = (e) => {
    const selectedManagerId = e.target.value;
    setCityData((prevCityData) => ({
      ...prevCityData,
      city_manager: selectedManagerId,
    }));
    setModifiedFields((prevModifiedFields) => ({
      ...prevModifiedFields,
      city_manager: selectedManagerId,
    }));
  };

  const handleFileButtonClick = (fieldName) => {
    document.getElementById(fieldName).click();
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

 
  useEffect(() => {
    const fetchTinyEditors = async () => {
      try {
        const token = localStorage.getItem('token') || Cookies.get('token') ;
        const response = await fetch(`${BASE_URL}/api/v1/city-tiny-editor/`);
        const data = await response.json();
        if (!response.ok) {
          throw new Error('Failed to fetch tiny editors');
        }
        // Filter out entries based on city ID
        const filteredEditors = data.results.filter(editor => editor.city === cityId);
        setTinyEditors(filteredEditors);
      } catch (error) {
        console.error('Error fetching tiny editors:', error);
      }
    };

    fetchTinyEditors();
  }, [cityId]);

  const handleUpdateCityContent = async () => {
    try {
      const token = Cookies.get('token');
      const promises = tinymceData.map(({ id, content, name }) => {
        // Find the corresponding editor in tinyEditors based on ID
        console.log("id", id);
        console.log("name", name);
        const editor = tinyEditors.find(editor => editor.id === id);
        // console.log("sending", editor);
        if (!editor) {
          throw new Error(`Editor with ID ${id} not found in tinyEditors`);
        }
        
        const formData = new FormData();
        
        formData.append('name', name); // Use the name from tinyEditors
        formData.append('content', content);
        // console.log("sending", editor);
        
        return fetch(`${BASE_URL}/api/v1/city-tiny-editor/${id}/`, {
          method: 'PATCH',
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        });
      });
      
      await Promise.all(promises);

      // Show success Snackbar
      setSnackbar({ open: true, severity: 'success', message: 'City content updated successfully. Reload page to see changes' });
    } catch (error) {
      console.error('Error updating city content:', error);
      // Show error Snackbar
      setSnackbar({ open: true, severity: 'error', message: 'Failed to update city content' });
    }
  };

  const stateColors = {
    in_review: '#FFC107',
    draft: '#9E9E9E',
    published: '#4CAF50'
  };

  const handleUpdateRoom = async () => {
    try {
      const token = Cookies.get('token');
      const formData = new FormData();
      setLoading(true);
      // Append modified fields to formData
      for (const key in modifiedFields) {
        if (key === "name" && !modifiedFields[key].trim()) {
          window.alert("The City Name field cannot be blank.");
          return; // Exit the function to prevent further processing
        }
        formData.append(key, modifiedFields[key]);        
      }
      cityData.state = 'draft';
      formData.append('state', 'draft');
      formData.append('is_active', isActive);
      const response = await fetch(`${BASE_URL}/api/v1/city/${cityId}/`, {
        method: 'PATCH',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Failed to update city');
      }

      // Show success Snackbar
      setSnackbar({ open: true, severity: 'success', message: 'City updated successfully. Reload page to see changes' });
    } catch (error) {
      console.error('Error updating city:', error);
      // Show error Snackbar
      setSnackbar({ open: true, severity: 'error', message: 'Failed to update city' });
      setLoading(false);
    } finally {
      setLoading(false); // Stop loading regardless of success or failure
    }
  };


  const handleRequestReview = async () => {
    try {
      // Get the current state of the city
    
      if (cityData.state === 'in_review') {
        alert('City already in review');
        return;
      }
      
      if (cityData.state === 'published') {
        alert('City already published');
        return;
      }
  
      if (cityData.state !== 'draft') {
        alert('Invalid city state');
        return;
      }
  
      const confirmed = window.confirm("Are you sure you want to request review by admin? This action cannot be reversed.");
  
      if (confirmed) {
        // Make a PATCH request to update the city state
        console.log("state-before",cityData.state);
        cityData.state = 'in_review';
        console.log("state-after",cityData.state);
        const token = localStorage.getItem('token') || Cookies.get('token') ;
        const response = await fetch(`${BASE_URL}/api/v1/city/${cityId}/`, {
          method: 'PATCH',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ state: 'in_review' }), // Update city state to "in_review"
        });
  
        if (!response.ok) {
          throw new Error('Failed to request review');
        }
  
        alert('City sent for review\nRefresh page to see the changes');

      }
    } catch (error) {
      console.error('Error requesting review:', error);
      alert('Failed to request review');
    }
  };
  

  return (
    <Box p={4}>
      <Typography variant="h4" gutterBottom>Edit City</Typography>
      <Box textAlign="center">
        <Box
          bgcolor={stateColors[cityData.state]}
          color="#fff"
          borderRadius={16}
          px={2}
          py={1}
          ml={2}
          mb={2}
          display="inline-block"
        >
          Current state of city: {cityData.state === 'in_review' ? 'In - Review' : cityData.state.charAt(0).toUpperCase() + cityData.state.slice(1)}
        </Box>
      </Box>
      
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '20px' }}>
        <Button variant="contained" color="primary" sx = {{ textTransform: 'none'}} id="request-review-button" onClick={handleRequestReview}>
          Request Review
        </Button>
        <Box mx={2}></Box>
        <Button variant="contained" onClick={onClose} startIcon={<ArrowBackIcon />} sx={{ bgcolor: 'yellow', color: '#000000', textTransform: 'none' }}>
          Go Back
        </Button>
      </div>
      <div>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
          <Typography variant="subtitle1" style={{ width: '150px' }}>City Name*</Typography>
          <TextField
            name="name"
            value={cityData.name}
            onChange={handleChange}
            fullWidth
          />
        </div>
        {/* City Thumbnail */}
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
          <Typography variant="subtitle1" style={{ width: '150px' }}>City Thumbnail</Typography>
          <Box display="flex" alignItems="center">
            <img
              src={cityData.cityscape}
              alt="cityscape"
              
              style={{ width: '150px', marginRight: '12px' }}
            />
            <Button
              variant="contained"
              onClick={() => handleFileButtonClick('cityscape')}
              sx = {{textTransform: 'none'}}
            >
             {cityData.cityscape ? 'Update City Thumbnail' : 'Upload City Thumbnail'}
            </Button>
            {/* {cityData.cityscape && (
              <FormControlLabel
                control={<Checkbox checked={clearCityscape} onChange={handleClearCityscapeChange} />}
                label="Clear Cityscape"
                sx={{ marginLeft: '20px' }}
              />
            )} */}
            <input
              type="file"
              accept="image/*"
              onChange={handleChange}
              style={{ display: 'none' }}
              id="cityscape" // Corrected id attribute
              name="cityscape" 
            />
          </Box>
        </div>
        {/* Intro Flyin Graphic */}
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
          <Typography variant="subtitle1" style={{ width: '150px' }}>Intro Flyin Graphic</Typography>
          <Box display="flex" alignItems="center">
            {cityData.flyin_graphic && (
              <img
                src={cityData.flyin_graphic}
                alt="Intro Flyin Graphic"
                style={{ width: '150px', marginRight: '12px' }}
              />
            )}
            <Button
              variant="contained"
              onClick={() => handleFileButtonClick('flyin_graphic')}
              sx = {{textTransform: 'none'}}
            >
              {cityData.flyin_graphic ? 'Update Intro Flyin Graphic' : 'Upload Intro Flyin Graphic'}
            </Button>
            {cityData.flyin_graphic && (
             <FormControlLabel
             control={<Checkbox checked={clearFlyinGraphic} onChange={handleClearFlyinGraphicChange} />}
             label="Clear Intro Flyin Graphic"
             sx={{ marginLeft: '20px' }} // Add marginLeft to create space
           />
           )}
            <input
              type="file"
              accept="image/*,video/*"
              onChange={handleChange}
              style={{ display: 'none' }}
              id="flyin_graphic"
              name="flyin_graphic"
            />
          </Box>
        </div>
        {/* Loop Graphic */}
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
          <Typography variant="subtitle1" style={{ width: '150px' }}>Loop Graphic</Typography>
          <Box display="flex" alignItems="center">
            {cityData.loop_graphic && (
              <img
                src={cityData.loop_graphic}
                alt="Loop Graphic"
                style={{ width: '150px', marginRight: '12px' }}
              />
            )}
            <Button
              variant="contained"
              onClick={() => handleFileButtonClick('loop_graphic')}
              sx = {{textTransform: 'none'}}
            >
              {cityData.loop_graphic ? 'Update Loop Graphic' : 'Upload Loop Graphic'}
            </Button>
            {cityData.loop_graphic && (
            <FormControlLabel
                control={<Checkbox checked={clearLoopGraphic} onChange={handleClearLoopGraphicChange} />}
                label="Clear Loop Graphic"
                sx={{ marginLeft: '20px' }}
            />
            )}
            <input
              type="file"
              accept="image/*,video/*"
              onChange={handleChange}
              style={{ display: 'none' }}
              id="loop_graphic"
              name="loop_graphic"
            />
          </Box>
        </div>
        {/* Support Audio */}
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
            <Typography variant="subtitle1" style={{ width: '150px' }}>Support Audio</Typography>
            <Box display="flex" alignItems="center">
              {/* Audio Player */}
              {cityData.support_audio && (
                <audio controls id="support_audio_preview" style={{ marginRight: '12px' }}>
                  <source src={cityData.support_audio} type="audio/mp3" />
                  Your browser does not support the audio element.
                </audio>
              )}
              {/* Upload Button */}
              <Button
                variant="contained"
                onClick={() => handleFileButtonClick('support_audio')}
                sx = {{textTransform: 'none'}}
              >
                {cityData.support_audio ? 'Update Support Audio' : 'Upload Support Audio'}
              </Button>
              {cityData.support_audio && (
              <FormControlLabel
                control={<Checkbox checked={clearAudio} onChange={handleClearSupportAudioChange} />}
                label="Clear Support Audio"
                sx={{ marginLeft: '20px' }}
              />
              )}
              {/* Input for uploading */}
              <input
                type="file"
                accept="audio/*, .mp3"
                onChange={handleChange}
                style={{ display: 'none' }}
                id="support_audio"
                name="support_audio"
              />
            </Box>
          </div>




        {/* Toggle Button for Active/Inactive */}
      {/* <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
        <Typography variant="subtitle1" style={{ width: '150px' }}>Active</Typography>
        <Switch // Step 3: Toggle Switch component
          checked={isActive} // Step 4: Value linked to isActive state
          onChange={(event) => {
            setIsActive(event.target.checked); // Step 3: Update isActive state
            setCityData({ ...cityData, is_active: event.target.checked }); // Step 3: Update cityData.is_active
          }}
          name="activeInactiveSwitch"
          color="primary"
        />
      </div> */}
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
        <Typography variant="subtitle1" style={{ width: '150px' }}>Active</Typography>
        <Switch
          checked={isActive}
          onChange={(event) => {
            setIsActive(event.target.checked); // Update isActive state
            setCityData({ ...cityData, is_active: event.target.checked }); // Update cityData.is_active
          }}
          name="activeInactiveSwitch"
          color="primary"
        />
      </div>
      {/* City Manager Dropdown */}
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
          <Typography variant="subtitle1" style={{ width: '150px' }}>City Manager</Typography>
          <FormControl fullWidth>
            <Select
              value={cityData.city_manager || ''} // Use city_manager ID from cityData
              onChange={handleCityManagerChange}
            >
              <MenuItem value="">None</MenuItem>
              {cityManagers.map((manager) => (
                <MenuItem key={manager.id} value={manager.id}>
                  {manager.name ? `${manager.name} - ${manager.email}` : manager.email}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </div>
      <Box bgcolor="lightblue" p={2} textAlign="center" mb={2}>
        TinyMCE Editor
      </Box>
      <TableContainer component={Paper} mb={4}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell><b>Name</b></TableCell>
              {/* <TableCell><b>Editor</b></TableCell> */}
              <TableCell><b>Action</b></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tinyEditors.map((editor, index) => (
              <TableRow key={index}>
                <TableCell>{editor.name}</TableCell>
                {/* <TableCell>
                  <Editor
                    apiKey="jwhx1c91nef9f7mcweeqb4oo3w8x6anij046gw5xf80r0l0n"
                    initialValue={editor.content}
                    init={{
                      height: 200,
                      menubar: false,
                      plugins: ['advlist autolink lists link image', 'charmap print preview anchor help', 'searchreplace visualblocks code', 'insertdatetime media table paste wordcount'],
                      toolbar: 'undo redo | formatselect | bold italic backcolor | \
                      alignleft aligncenter alignright alignjustify | \
                      bullist numlist outdent indent | removeformat | help',
                    }}
                    onEditorChange={(content, editor) => handleTinyMCEChange(content, editor)}
                  />
                </TableCell> */}
                <TableCell>
                  <Button
                    variant="contained"
                    onClick={() => handleEditContent(editor)}
                    style={{ marginRight: '10px' }}
                    sx = {{textTransform: 'none'}}
                  >
                    Edit
                  </Button>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={() => handleDeleteContent(editor.id)}
                    sx = {{textTransform: 'none'}}
                  >
                    Delete
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <MuiAlert elevation={6} variant="filled" onClose={handleCloseSnackbar} severity={snackbar.severity}>
          {snackbar.message}
        </MuiAlert>
      </Snackbar>
      <Modal open={modalOpen} onClose={handleCloseModal}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '50%',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography variant="h6" gutterBottom>Edit TinyMCE Content</Typography>
          <TextField
            label="Name"
            variant="outlined"
            fullWidth
            value={updatedName}
            onChange={(e) => setUpdatedName(e.target.value)}
            mb={2}
          />
          {/* <Editor
            apiKey="jwhx1c91nef9f7mcweeqb4oo3w8x6anij046gw5xf80r0l0n"
            initialValue={updatedContent}
            init={{
              height: 400,
              menubar: false,
              plugins: [
                'advlist autolink lists link image', 
                'charmap print preview anchor', 
                'searchreplace visualblocks code', 
                'insertdatetime media table paste wordcount'
              ],
              toolbar: 'undo redo | formatselect | bold italic backcolor | \
              alignleft aligncenter alignright alignjustify | \
              bullist numlist outdent indent | removeformat | help',
            }}
            onEditorChange={(content) => setUpdatedContent(content)}
          /> */}
          <Editor
            apiKey="jwhx1c91nef9f7mcweeqb4oo3w8x6anij046gw5xf80r0l0n" 
            initialValue={selectedEditor && selectedEditor.content}
            init={{
              height: 400,
              menubar: false,
              plugins: ['advlist autolink lists link image', 'charmap print preview anchor help', 'searchreplace visualblocks code', 'insertdatetime media table paste wordcount'],
              toolbar: 'undo redo | formatselect | bold italic | alignleft aligncenter alignright | bullist numlist outdent indent | help'
            }}
            onEditorChange={(content) => setUpdatedContent(content)}
          />
          <Box textAlign="right" mt={2}>
            <Button variant="contained" onClick={handleCloseModal} style={{ marginRight: '10px',textTransform: 'none' }}>Cancel</Button>
            <Button variant="contained" color="primary" sx = {{textTransform: 'none'}} onClick={handleUpdateContent}>Save</Button>
          </Box>
        </Box>
      </Modal>
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
        <Button variant="contained" color="primary" sx = {{textTransform: 'none'}} onClick={handleUpdateRoom} disabled={loading}>  {loading && <CircularProgress size={24} style={{ position: 'absolute', top: '50%', left: '50%', marginTop: -12, marginLeft: -12, color: 'white' }} />} Update</Button>
        <Box mx={2}></Box>
        <Button variant="contained" color="error" sx = {{textTransform: 'none'}} startIcon={<DeleteIcon />} onClick={handleDeleteRoom}>Delete</Button>
      </div>
    </Box>
  );
};

export default EditCityForm;
