// import React, { useState, useEffect } from 'react';
// import Typography from '@mui/material/Typography';
// import Button from '@mui/material/Button';
// import ReviewRooms from './ReviewRooms';
// import ReviewEvents from './ReviewEvents';
// import ReviewUsers from './ReviewUsers';
// import Cookies from 'js-cookie';
// import { BASE_URL } from './constants';
// import ReviewCities from './ReviewCities';
// import Card from '@mui/material/Card';

// const HomePage = () => {
//   const [selectedPage, setSelectedPage] = useState(null);
//   const [showComp, setShowComp] = useState(false);
//   const [userRole, setUserRole] = useState(null);
//   const [isAdmin, setIsAdmin] = useState(false);

//   useEffect(() => {
//     const fetchUserRole = async () => {
//       try {
//         const token = localStorage.getItem('token') || Cookies.get('token') ;
//         const response = await fetch(`${BASE_URL}/api/v1/user-profile/`, {
//           headers: {
//             Authorization: `Bearer ${token}`,
//           },
//         });
//         if (!response.ok) {
//           throw new Error('Failed to fetch user role');
//         }
//         const roleData = await response.json();
//         const { results } = roleData; 
//         console.log("data",roleData);
//         if (results && results.length > 0) {
//           const userData = results[0]; // Access the first object in the results array
//           const { user_type, is_admin } = userData; // Destructure user_type and is_admin
        
//           // Now you can use user_type and is_admin as needed
//           console.log("User type:", user_type);
//           console.log("Is admin:", is_admin);
//           setUserRole(user_type);
//           setIsAdmin(is_admin);
//         } else {
//           console.error("No user data found in roleData");
//         }
//         // setUserRole(user_type);
//         // setIsAdmin(is_admin);
//       } catch (error) {
//         console.error('Error fetching user role:', error);
//       }
//     };

//     fetchUserRole();
//   }, []);

//   const handlePageChange = (pageName) => {
//     setSelectedPage(pageName);
//     setShowComp(true);
//   };

//   const handleClose = () => {
//     setShowComp(false);
//   };

//   const renderPage = () => {
//     switch (selectedPage) {
//       case 'reviewRooms':
//         return <ReviewRooms onClose={handleClose} />;
//       case 'reviewCities':
//         return <ReviewCities onClose={handleClose} />;
//       case 'reviewEvents':
//         return <ReviewEvents onClose={handleClose} />;
//       case 'reviewUsers':
//         return <ReviewUsers onClose={handleClose} />;
//       default:
//         return (
//           <div style={{ padding: '20px', maxWidth: '600px', margin: 'auto' }}>
//             <Typography variant="h4" gutterBottom align="center">
//               Dashboard 
//             </Typography>
//             <div style={{ display: 'flex', justifyContent: 'center' }}>
//               {isAdmin || userRole === 'ccgadmin' ? (
//                 <>
//                   <Button
//                     variant="contained"
//                     color="inherit"
//                     style={{ margin: '10px' }}
//                     onClick={() => handlePageChange('reviewRooms')}
//                   >
//                     Review Rooms
//                   </Button>
//                   <Button
//                     variant="contained"
//                     color="inherit"
//                     style={{ margin: '10px' }}
//                     onClick={() => handlePageChange('reviewEvents')}
//                   >
//                     Review Events
//                   </Button>
//                   <Button
//                     variant="contained"
//                     color="inherit"
//                     style={{ margin: '10px' }}
//                     onClick={() => handlePageChange('reviewUsers')}
//                   >
//                     Review Users
//                   </Button>
//                 </>
//               ) : null}
//             </div>
//           </div>
//         );
//     }
//   };

//   return (
//     <div>
//       {showComp ? (
//         renderPage()
//       ) : (
//         <div style={{ padding: '20px', maxWidth: '600px', margin: 'auto' }}>
//           <Typography variant="h4" gutterBottom align="center">
//             Welcome to Stem-City Dashboard Home
//           </Typography>
//           <div style={{ display: 'flex', justifyContent: 'center' }}>
//             {isAdmin || userRole === 'ccgadmin' ? (
//               <>
//                 <Button
//                   variant="contained"
//                   color="inherit"
//                   style={{ margin: '10px' }}
//                   onClick={() => handlePageChange('reviewCities')}
//                 >
//                   Review Cities
//                 </Button>
//                 <Button
//                   variant="contained"
//                   color="inherit"
//                   style={{ margin: '10px' }}
//                   onClick={() => handlePageChange('reviewRooms')}
//                 >
//                   Review Rooms
//                 </Button>
//                 <Button
//                   variant="contained"
//                   color="inherit"
//                   style={{ margin: '10px' }}
//                   onClick={() => handlePageChange('reviewEvents')}
//                 >
//                   Review Events
//                 </Button>
//                 <Button
//                   variant="contained"
//                   color="inherit"
//                   style={{ margin: '10px' }}
//                   onClick={() => handlePageChange('reviewUsers')}
//                 >
//                   Review Users
//                 </Button>
//               </>
//             ) : null}
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default HomePage;

import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia'; // Import CardMedia component
import ReviewRooms from './ReviewRooms';
import ReviewEvents from './ReviewEvents';
import ReviewUsers from './ReviewUsers';
import Cookies from 'js-cookie';
import { BASE_URL } from './constants';
import ReviewCities from './ReviewCities';
import PersonIcon from '@mui/icons-material/Person'; // Import user icon
import room_image from '../components/assets/room_image.jpeg'
import UserCURD from './UserCURD';

const HomePage = () => {
  const [selectedPage, setSelectedPage] = useState(null);
  const [showComp, setShowComp] = useState(false);
  const [userRole, setUserRole] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const fetchUserRole = async () => {
      try {
        const token = localStorage.getItem('token') || Cookies.get('token') ;
        const response = await fetch(`${BASE_URL}/api/v1/user-profile/`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (!response.ok) {
          throw new Error('Failed to fetch user role');
        }
        const roleData = await response.json();
        const { results } = roleData; 
        console.log("data",roleData);
        if (results && results.length > 0) {
          const userData = results[0]; 
          const { user_type, is_admin } = userData; 
          console.log("User type:", user_type);
          console.log("Is admin:", is_admin);
          setUserRole(user_type);
          setIsAdmin(is_admin);
        } else {
          console.error("No user data found in roleData");
        }
      } catch (error) {
        console.error('Error fetching user role:', error);
      }
    };

    fetchUserRole();
  }, []);

  const handlePageChange = (pageName) => {
    setSelectedPage(pageName);
    setShowComp(true);
  };

  const handleClose = () => {
    setShowComp(false);
  };

  const pageImages = {
    'reviewCities': "https://imageio.forbes.com/specials-images/imageserve/657a9446112ac88e5f9fd857/New-York-City-best-city-in-the-world/960x0.jpg?height=473&width=711&fit=bounds",
    //'reviewRooms': "https://imageio.forbes.com/specials-images/imageserve/657a9446112ac88e5f9fd857/New-York-City-best-city-in-the-world/960x0.jpg?height=473&width=711&fit=bounds",
    'reviewEvents': "https://res.cloudinary.com/dwzmsvp7f/image/fetch/q_75,f_auto,w_1316/https%3A%2F%2Fmedia.insider.in%2Fimage%2Fupload%2Fc_crop%2Cg_custom%2Fv1519627962%2Fvltlogy23k1iid9pjffx.jpg",
    'reviewUsers': <PersonIcon />, // Use PersonIcon component directly
  };

  const renderPage = () => {
    switch (selectedPage) {
      case 'reviewRooms':
        return <ReviewRooms onClose={handleClose} />;
      case 'reviewCities':
        return <ReviewCities onClose={handleClose} />;
      case 'reviewEvents':
        return <ReviewEvents onClose={handleClose} />;
      case 'reviewUsers':
        // return <ReviewUsers onClose={handleClose} />;
        return <UserCURD/>;
      default:
        return (
          <div style={{ padding: '20px', maxWidth: '800px', margin: 'auto' }}>
            <Typography variant="h4" gutterBottom align="center">
              Dashboard 
            </Typography>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              {isAdmin || userRole === 'ccgadmin' ? (
                <>
                  <Card onClick={() => handlePageChange('reviewRooms')} sx={{ margin: '10px', cursor: 'pointer', minWidth: '300px' }}>
                    <CardMedia
                      component="img"
                      image={pageImages['reviewRooms']}
                      alt="Review Rooms"
                      sx={{ height: 140 }}
                    />
                    <CardContent>
                      <Typography variant="h6">Review Rooms</Typography>
                    </CardContent>
                  </Card>
                  <Card onClick={() => handlePageChange('reviewEvents')} sx={{ margin: '10px', cursor: 'pointer', minWidth: '300px' }}>
                    <CardMedia
                      component="img"
                      image={pageImages['reviewEvents']}
                      alt="Review Events"
                      sx={{ height: 140 }}
                    />
                    <CardContent>
                      <Typography variant="h6">Review Events</Typography>
                    </CardContent>
                  </Card>
                  <Card onClick={() => handlePageChange('reviewUsers')} sx={{ margin: '10px', cursor: 'pointer', minWidth: '300px' }}>
                    <CardMedia
                      component="img"
                      image={pageImages['reviewUsers']}
                      alt="Review Users"
                      sx={{ height: 140 }}
                    />
                    <CardContent>
                      <Typography variant="h6">Review Users</Typography>
                    </CardContent>
                  </Card>
                </>
              ) : null}
            </div>
          </div>
        );
    }
  };

  return (
    <div>
      {showComp ? (
        renderPage()
      ) : (
        <div style={{ padding: '20px', maxWidth: '800px', margin: 'auto' }}>
          <Typography variant="h4" gutterBottom align="center">
            Welcome to Stem-City 3D CMS
          </Typography>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            {isAdmin || userRole === 'ccgadmin' ? (
              <>
                <Card onClick={() => handlePageChange('reviewCities')} sx={{ margin: '10px', cursor: 'pointer', minWidth: '300px' }}>
                  <CardMedia
                    component="img"
                    image={pageImages['reviewCities']}
                    alt="Manage Cities"
                    sx={{ height: 140 }}
                  />
                  <CardContent>
                    <Typography variant="h6">Manage Cities</Typography>
                  </CardContent>
                </Card>
                <Card onClick={() => handlePageChange('reviewRooms')} sx={{ margin: '10px', cursor: 'pointer', minWidth: '300px' }}>
                  <CardMedia
                    component="img"
                    image={room_image}
                    alt="Manage Rooms"
                    sx={{ height: 140 }}
                  />
                  <CardContent>
                    <Typography variant="h6">Manage Rooms</Typography>
                  </CardContent>
                </Card>
                <Card onClick={() => handlePageChange('reviewEvents')} sx={{ margin: '10px', cursor: 'pointer', minWidth: '300px' }}>
                  <CardMedia
                    component="img"
                    image={pageImages['reviewEvents']}
                    alt="Manage Events"
                    sx={{ height: 140 }}
                  />
                  <CardContent>
                    <Typography variant="h6">Manage Events</Typography>
                  </CardContent>
                </Card>
                <Card onClick={() => handlePageChange('reviewUsers')} sx={{ margin: '10px', cursor: 'pointer', minWidth: '300px' }}>
                  <CardContent>
                    <PersonIcon sx={{ fontSize: 140, color: '#757575' }} />
                    <Typography variant="h6">Manage Users</Typography>
                  </CardContent>
                </Card>

              </>
            ) : null}
          </div>
        </div>
      )}
    </div>
  );
};

export default HomePage;
