// // AppRouter.js
// import React from 'react';
// import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
// import SignupForm from './SignupForm';
// import LoginForm from './LoginForm';
// import MiniDrawer from './drawer';
// import AdminSignupForm from './AdminSignup';
// import { BASE_URL } from './constants'; // Import BASE_URL from constants

// const AppRouter = () => {
//   return (
//     <Router>
//       <Routes>
//         <Route path={`${BASE_URL}/signup`} element={<SignupForm />} />
//         <Route path={`${BASE_URL}/login`} element={<LoginForm />} />
//         <Route path={`${BASE_URL}/dashboard`} element={<MiniDrawer />} />
//         <Route path={`${BASE_URL}/adminsignup`} element={<AdminSignupForm />} />
//       </Routes>
//     </Router>
//   );
// };

// export default AppRouter;

import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import SignupForm from './SignupForm';
import LoginForm from './LoginForm';
import MiniDrawer from './drawer';
import AdminSignupForm from './AdminSignup';
import withAuth from './auth';
import ProfilePage from './ProfilePage';
import ReviewUsers from './ReviewUsers';
import CreateRoomForm from './CreateRoomForm';
import Rooms from './rooms';
import CreateRoomwithLayout from './CreateRoomwithLayout';
import RoomwithLayout from './RoomwithLayout';
import EventDetailswithLayout from './EventDetailswithLayout';
import ProfilePagewith from './ProfilePagewith';
import CreateCitywithLayout from './CreateCityForm';
import CityithLayout from './CitywithLayout';
import ResetPasswordForm from './ResetPasswordForm';
import RoomDetailswithLayout from './RoomDetailswithLayout';

const AppRouter = () => {
  return (
    <Router>
      <Routes>
        <Route path="/signup" element={<SignupForm />} />
        <Route path="/account/reset-password/:UID/:token" element={<ResetPasswordForm />} />
        <Route path="/" element={<LoginForm />} />
        <Route path="/login" element={<LoginForm />} />
        <Route path="/dashboard/*" element={<MiniDrawer />}/>
        <Route path="/myprofile" element={<ProfilePagewith />} />
        {/* <Route path="/myprofile" element={<ProfilePage />} /> */}
        <Route path ="/adminsignup" element ={<AdminSignupForm />} />
        <Route path="/dashboard" element={<MiniDrawer />} />
        <Route path="/createRoom" element={<CreateRoomwithLayout />} />
        <Route path="/createCity" element={<CreateCitywithLayout />} />
        {/* <Route path="/createRoom" element={<CreateRoomForm />} /> */}
        {/* <Route path="/rooms" element={<Rooms />} /> */}
        <Route path="/rooms" element={<RoomwithLayout />} />
        <Route path="/users" element={<RoomDetailswithLayout />} />
        <Route path="/cities" element={<CityithLayout />} />
        <Route path="/events" element={<EventDetailswithLayout/>} />
        <Route path="/dashboard" element={<MiniDrawer />} />
        <Route path="/dashboard" element={<MiniDrawer />} />
        <Route path="/dashboard/myprofile" element={<ProfilePage />} />
        {/* <Route path="/dashboard/review-users" element={<ReviewUsers />} /> */}
      </Routes>
    </Router>
  ); 
};

export default AppRouter;
