// // Rooms.js
// import React, { useState, useEffect } from 'react';
// import Typography from '@mui/material/Typography';
// import Box from '@mui/material/Box';
// import Button from '@mui/material/Button';
// import RoomIcon from '@mui/icons-material/Room';
// import Table from '@mui/material/Table';
// import TableBody from '@mui/material/TableBody';
// import TableCell from '@mui/material/TableCell';
// import TableContainer from '@mui/material/TableContainer';
// import TableHead from '@mui/material/TableHead';
// import TableRow from '@mui/material/TableRow';
// import EditRoomForm from './EditRoomForm'; // Import the EditRoomForm component
// import { useParams } from 'react-router-dom'; // Import useParams from react-router-dom
// import Cookies from 'js-cookie';
// import { BASE_URL } from './constants';
// import RoomComponents from './RoomComponents';
// import DeleteIcon from '@mui/icons-material/Delete';
// import EditIcon from '@mui/icons-material/Edit';

// const Rooms = () => {
//   const [rooms, setRooms] = useState([]);
//   const [editRoomId, setEditRoomId] = useState(null); // State to manage the room ID being edited
//   const { roomId } = useParams(); // Get roomId from route params
//   const [showRoomComponents, setShowRoomComponents] = useState(false);
//   const [selectedRoom, setSelectedRoom] = useState(null); // State to store the selected room data

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const isAdminCookie = Cookies.get("isAdmin");
//         const token = Cookies.get('token'); // Get the token from the cookie
//         let endpoint;
//         if (isAdminCookie.toString() === 'true') {
//           endpoint = `${BASE_URL}/api/v1/rooms-list/`; // Endpoint for admin
//         } else {
//           endpoint = `${BASE_URL}/api/v1/rooms/`; // Default endpoint
//         }
//         const response = await fetch(endpoint, {
//           headers: {
//             Authorization: `Bearer ${token}`, // Include the token in the Authorization header
//           },
//         });
//         if (!response.ok) {
//           throw new Error('Failed to fetch rooms');
//         }
//         const data = await response.json();
//         setRooms(data.results);
//       } catch (error) {
//         console.error('Error fetching rooms:', error);
//       }
//     };

//     fetchData();
//   }, []);

//   const formatDate = (dateString) => {
//     const date = new Date(dateString);
//     return date.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' });
//   };

//   const handleEditRoom = (roomId) => {
//     setEditRoomId(roomId);
//   };

//   const handleCancelEdit = () => {
//     setEditRoomId(null);
//   };

//   const generateRoomMapperLink = (roomId) => {
//     return `${BASE_URL}/room/mapper/${roomId}`;
//   };

//   const handleShowRoomComponents = async (roomId) => {
//     try {
//       const token = Cookies.get('token'); // Get the token from the cookie
//       const response = await fetch(`${BASE_URL}/api/v1/room-detail/${roomId}/`, {
//         headers: {
//           Authorization: `Bearer ${token}`, // Include the token in the Authorization header
//         },
//       });
//       if (!response.ok) {
//         throw new Error('Failed to fetch room components');
//       }
//       const data = await response.json();
//       setSelectedRoom(data);
//       setShowRoomComponents(true);
//     } catch (error) {
//       console.error('Error fetching room components:', error);
//     }
//   };

//   const handleCloseRoomComponents = () => {
//     setShowRoomComponents(false);
//   };
  
//   const stateMap = {
//     draft: 'Draft',
//     in_review: 'In-Review',
//     published: 'Published'
//   };

//   const handleDeleteCity = async (roomId) => {
//     try {
//       // Ask for confirmation before deleting
//       const confirmed = window.confirm("Are you sure you want to delete this room? This action won't be reversible.");
//       if (!confirmed) return;
  
//       const token = Cookies.get('token');
//       const response = await fetch(`${BASE_URL}/api/v1/rooms/${roomId}/`, {
//         method: 'DELETE',
//         headers: {
//           Authorization: `Bearer ${token}`,
//         },
//       });
//       if (!response.ok) {
//         throw new Error('Failed to delete room');
//       }
//       // Show success alert after deleting
//       window.alert("Room deleted successfully.");
  
//       // Remove the deleted city from the cities array
//       setRooms(rooms.filter(room => room.id !== roomId));
//     } catch (error) {
//       console.error('Error deleting room:', error);
//       // Show error alert if deletion fails
//       window.alert("Failed to delete room. Please try again later.");
//     }
//   };

//   return (
//     <Box>
//       {showRoomComponents ? (
//         <RoomComponents roomData={selectedRoom} onClose={handleCloseRoomComponents} />
//       ) : (
//         <>
//           <Typography variant="h5" gutterBottom>
//             Your Rooms
//           </Typography>
//           {editRoomId ? (
//             <EditRoomForm roomId={editRoomId} onClose={handleCancelEdit} /> // Render EditRoomForm if editRoomId is not null
//           ) : (
//             <TableContainer>
//               <Table>
//                 <TableHead>
//                   <TableRow>
//                     <TableCell style={{ fontWeight: 'bold' }}>Room Thumbnail</TableCell>
//                     <TableCell style={{ fontWeight: 'bold' }}>Room Name</TableCell>
//                     <TableCell style={{ fontWeight: 'bold' }}>Created At</TableCell>
//                     <TableCell style={{ fontWeight: 'bold' }}>State</TableCell>
//                     <TableCell style={{ fontWeight: 'bold' }}>Actions</TableCell>
//                   </TableRow>
//                 </TableHead>
//                 <TableBody>
//                   {rooms.map(room => (
//                     <TableRow key={room.id}>
//                       <TableCell>
//                         <img src={room.room_thumbnail} alt={room.name} style={{ width: 100 }} />
//                       </TableCell>
//                       <TableCell>{room.name}</TableCell>
//                       <TableCell>{formatDate(room.created_at)}</TableCell>
//                       <TableCell>{stateMap[room.state]}</TableCell>
//                       <TableCell>
//                       <div style={{ display: 'inline' }}>
//                         <Button variant="contained" color="primary" style={{ marginRight: 7, textTransform: 'none' }} startIcon={<EditIcon />} onClick={() => handleEditRoom(room.id)}>Edit Room</Button>
//                         <Button variant="contained" color="secondary" href={generateRoomMapperLink(room.id)} target="_blank" style={{ minWidth: 100, textTransform: 'none' }}>Room Mapper</Button>
//                         <Button variant="contained" color="primary" style={{ marginLeft: 7, textTransform: 'none' }} onClick={() => handleShowRoomComponents(room.id)}>
//                           Room Components
//                         </Button>
//                         <Button variant="contained" color="error" style={{ marginLeft: 8, textTransform: 'none' }} startIcon={<DeleteIcon />} onClick={() => handleDeleteCity(room.id)}>Delete Room</Button>
//                       </div>
//                     </TableCell>

//                     </TableRow>
//                   ))}
//                 </TableBody>
//               </Table>
//             </TableContainer>
//           )}
//         </>
//       )}
//     </Box>
//   );
// };

// export default Rooms;
import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import CircularProgress from '@mui/material/CircularProgress';
import Table from '@mui/material/Table';
import Pagination from '@mui/lab/Pagination';
import RoomComponents from './RoomComponents';
import EditRoomForm from './EditRoomForm';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useParams } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { BASE_URL } from './constants';

const cookies = new Cookies();

const Rooms = () => {
  const [rooms, setRooms] = useState([]);
  const [editRoomId, setEditRoomId] = useState(null);
  const [showRoomComponents, setShowRoomComponents] = useState(false);
  const [selectedRoom, setSelectedRoom] = useState(null);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const { roomId } = useParams();
  const itemsPerPage = 10;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const isAdminCookie = cookies.get('isAdmin');
        let endpoint;
        if (isAdminCookie.toString() === 'true') {
          endpoint = `${BASE_URL}/api/v1/rooms-list/?page=${currentPage}&page_size=${itemsPerPage}`;
        } else {
          endpoint = `${BASE_URL}/api/v1/rooms/?page=${currentPage}&page_size=${itemsPerPage}`;
        }
        const token = localStorage.getItem('token') || Cookies.get('token') ;
        const response = await fetch(endpoint, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (!response.ok) {
          throw new Error('Failed to fetch rooms');
        }
        const data = await response.json();
        setRooms(data.results);
        setTotalPages(Math.ceil(data.count / itemsPerPage));
        setLoading(false);
      } catch (error) {
        console.error('Error fetching rooms:', error);
      }
    };

    fetchData();
  }, [currentPage]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    });
  };

  const handleEditRoom = (roomId) => {
    setEditRoomId(roomId);
  };

  const handleCancelEdit = () => {
    setEditRoomId(null);
  };

  const generateRoomMapperLink = (roomId) => {
    return `${BASE_URL}/room/mapper/${roomId}`;
  };

  const handleShowRoomComponents = async (roomId) => {
    try {
      const token = cookies.get('token');
      const response = await fetch(`${BASE_URL}/api/v1/room-detail/${roomId}/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        throw new Error('Failed to fetch room components');
      }
      const data = await response.json();
      setSelectedRoom(data);
      setShowRoomComponents(true);
    } catch (error) {
      console.error('Error fetching room components:', error);
    }
  };

  const handleCloseRoomComponents = () => {
    setShowRoomComponents(false);
  };

  const handleDeleteRoom = async (roomId) => {
    try {
      const confirmed = window.confirm(
        "Are you sure you want to delete this room? This action won't be reversible."
      );
      if (!confirmed) return;

      const token = cookies.get('token');
      const response = await fetch(`${BASE_URL}/api/v1/rooms/${roomId}/`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        throw new Error('Failed to delete room');
      }
      window.alert('Room deleted successfully.');
      setRooms(rooms.filter((room) => room.id !== roomId));
    } catch (error) {
      console.error('Error deleting room:', error);
      window.alert('Failed to delete room. Please try again later.');
    }
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const stateMap = {
    draft: 'Draft',
    in_review: 'In-Review',
    published: 'Published',
  };

  const paginatedRooms = rooms.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  return (
    <Box>
      {showRoomComponents ? (
        <RoomComponents roomData={selectedRoom} onClose={handleCloseRoomComponents} />
      ) : (
        <>
          <Typography variant="h5" gutterBottom>
            Your Rooms
          </Typography>
          {editRoomId ? (
            <EditRoomForm roomId={editRoomId} onClose={handleCancelEdit} />
          ) : (
            <>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ fontWeight: 'bold' }}>Room Thumbnail</TableCell>
                      <TableCell style={{ fontWeight: 'bold' }}>Room Name</TableCell>
                      <TableCell style={{ fontWeight: 'bold' }}>Created At</TableCell>
                      <TableCell style={{ fontWeight: 'bold' }}>State</TableCell>
                      <TableCell style={{ fontWeight: 'bold' }}>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {loading ? (
                      <TableRow>
                        <TableCell colSpan={5} style={{ textAlign: 'center' }}>
                          <CircularProgress />
                        </TableCell>
                      </TableRow>
                    ) : (
                      paginatedRooms.map((room) => (
                        <TableRow key={room.id}>
                          <TableCell>
                            <img src={room.room_thumbnail} alt={room.name} style={{ width: 100 }} />
                          </TableCell>
                          <TableCell>{room.name}</TableCell>
                          <TableCell>{formatDate(room.created_at)}</TableCell>
                          <TableCell>{stateMap[room.state]}</TableCell>
                          <TableCell>
                            <Button
                              variant="contained"
                              color="primary"
                              style={{ marginRight: 7, textTransform: 'none' }}
                              startIcon={<EditIcon />}
                              onClick={() => handleEditRoom(room.id)}
                            >
                              Edit Room
                            </Button>
                            <Button
                              variant="contained"
                              color="secondary"
                              href={generateRoomMapperLink(room.id)}
                              target="_blank"
                              style={{ minWidth: 100, textTransform: 'none' }}
                            >
                              Room Mapper
                            </Button>
                            <Button
                              variant="contained"
                              color="primary"
                              style={{ marginLeft: 7, textTransform: 'none' }}
                              onClick={() => handleShowRoomComponents(room.id)}
                            >
                              Room Components
                            </Button>
                            <Button
                              variant="contained"
                              color="error"
                              style={{ marginLeft: 8, textTransform: 'none' }}
                              startIcon={<DeleteIcon />}
                              onClick={() => handleDeleteRoom(room.id)}
                            >
                              Delete Room
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <Box mt={2} display="flex" justifyContent="center">
                <Pagination
                  count={totalPages}
                  page={currentPage}
                  onChange={handlePageChange}
                  color="primary"
                />
              </Box>
            </>
          )}
        </>
      )}
    </Box>
  );
};

export default Rooms;
