import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Cookies from "js-cookie";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { BASE_URL } from "./constants";
import CloseIcon from "@mui/icons-material/Close";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CircularProgress from '@mui/material/CircularProgress';

const AddUser = ({ onClose, updateUserList }) => {
  const [eventData, setEventData] = useState({
    name: "",
    email: "",
    is_active: "",
    profile_photo: null,
    phone_number: "",
  });

  const [errors, setErrors] = useState({
    name: false,
    email: false,
    phone_number: false,
    profile_photo: false,
    is_active: false,
    noImage: false, // new state for tracking no image error
  });
  const [imageSelected, setImageSelected] = useState(false); // state to track if image is selected
  const [loading, setLoading] = useState(false);
  const handleChange = (e) => {
    const { name, value } = e.target;

    // if (name === "phone_number") {
    //   if (value.length < 10) {
    //     // toast.error("Phone number must be at least 10 digits");
    //     setErrors((prevErrors) => ({
    //       ...prevErrors,
    //       phone_number: "Phone number must be at least 10 digits",
    //     }));
    //   } else if (!/^\d{10}$/.test(value)) {
    //     setErrors((prevErrors) => ({
    //       ...prevErrors,
    //       phone_number: "Phone number must be exactly 10 digits",
    //     }));
    //   } else {
    //     setErrors((prevErrors) => ({
    //       ...prevErrors,
    //       phone_number: false,
    //     }));
    //   }
    //   setEventData((prevData) => ({
    //     ...prevData,
    //     [name]: value,
    //   }));
    // } else if (name === "email") {
    if (name === "email") {
      if (!/\S+@\S+\.\S+/.test(value) && value.trim() !== "") {
        setErrors((prevErrors) => ({
          ...prevErrors,
          email: "Please enter a valid email address",
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          email: false,
        }));
      }
      setEventData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    } else {
      setEventData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: false,
      }));
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setEventData((prevData) => ({
      ...prevData,
      profile_photo: file,
    }));
    // setErrors((prevErrors) => ({
    //   ...prevErrors,
    //   noImage: false, // Resetting noImage error state when an image is selected
    // }));
  };

  const handleSubmit = async () => {
    if ( errors.email) {
      // If there are errors, do not proceed with submission
      return;
    }

    if (
      !eventData.name ||
      !eventData.email 
    ) {
      setErrors({
        ...errors,
        name: !eventData.name,
        email: !eventData.email,
        // phone_number: !eventData.phone_number,
        // profile_photo: !eventData.profile_photo,
        // is_active: !eventData.is_active,
        // noImage: !eventData.profile_photo, // set noImage to true if no image is selected
      });

      return; // Stop submission if any required field is empty
    }
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("name", eventData.name);
      formData.append("email", eventData.email);
      formData.append("phone_number", eventData.phone_number);
      formData.append("profile_photo", eventData.profile_photo);

      const token = localStorage.getItem("token") || Cookies.get("token");
      const response = await fetch(`${BASE_URL}/api/v1/users-list/`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });

      if (!response.ok) {
        toast.error(`Email already exists`)
        return
      }

      resetForm();
      toast.success("User created successfully");
      updateUserList();
      onClose();
    } catch (error) {
      console.error("Error creating user:", error);
      toast.error(error);
      setLoading(false); // Stop loading on error
    } finally {
      setLoading(false); // Stop loading regardless of success or failure
    }
  };

  const resetForm = () => {
    setEventData({
      name: "",
      email: "",
      is_active: null,
      profile_photo: null,
      phone_number: "",
    });
    setErrors({
      name: false,
      email: false,
      phone_number: false,
      profile_photo: false,
      is_active: false,
      noImage: false, // reset noImage state
    });
    setImageSelected(false); // reset imageSelected state
  };

  return (
    <Dialog open={true} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6">Add User</Typography>
          <Button
            onClick={onClose}
            startIcon={<CloseIcon />}
            style={{
              backgroundColor: "#fdd835",
              color: "#424242",
              fontWeight: "bold",
              boxShadow: "none",
            }}
          >
            Close
          </Button>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography
              variant="subtitle1"
              style={{
                marginBottom: "8px",
                color: "#424242",
                fontWeight: "bold",
              }}
            >
              Name
            </Typography>
            <TextField
              name="name"
              value={eventData.name}
              onChange={handleChange}
              fullWidth
              variant="outlined"
              error={errors.name}
              helperText={errors.name && "Please fill in this field"}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="subtitle1"
              style={{
                marginBottom: "8px",
                color: "#424242",
                fontWeight: "bold",
              }}
            >
              email
            </Typography>
            <TextField
              name="email"
              value={eventData.email}
              onChange={handleChange}
              multiline
              fullWidth
              variant="outlined"
              error={errors.email}
              helperText={
                errors.email &&
                "Email can't be empty and  must be in proper format"
              }
            />
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="subtitle1"
              style={{
                marginBottom: "8px",
                color: "#424242",
                fontWeight: "bold",
              }}
            >
              Phone Number
            </Typography>
            <TextField
              name="phone_number"
              value={eventData.phone_number}
              onChange={handleChange}
              fullWidth
              variant="outlined"
              label="Phone Number"
              type="number" // Set type to "number" to allow only numeric input
              // error={errors.phone_number}
              // helperText={
              //   errors.phone_number &&
              //   "Phone number can't be empty and  must be exactly 10 digits"
              // }
            />
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="subtitle1"
              style={{
                marginBottom: "8px",
                color: "#424242",
                fontWeight: "bold",
              }}
            >
              Profile Photo
            </Typography>
            <input type="file" accept="image/*" onChange={handleFileChange} />

            {eventData.profile_photo && (
              <img
                src={URL.createObjectURL(eventData.profile_photo)}
                alt="Profile"
                style={{ maxWidth: "100px", maxHeight: "100px" }}
              />
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled = {loading}
          style={{
            borderRadius: "20px",
            fontWeight: "bold",
            boxShadow: "none",
            textTransform: "none",
          }}
        >
           {loading && <CircularProgress size={24} style={{ position: 'absolute', top: '50%', left: '50%', marginTop: -12, marginLeft: -12, color: 'white' }} />}
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddUser;
