// import React, { useState, useEffect } from "react";
// import Box from "@mui/material/Box";
// import Typography from "@mui/material/Typography";
// import Button from "@mui/material/Button";
// import Table from "@mui/material/Table";
// import TableBody from "@mui/material/TableBody";
// import TableCell from "@mui/material/TableCell";
// import TableContainer from "@mui/material/TableContainer";
// import TableHead from "@mui/material/TableHead";
// import TableRow from "@mui/material/TableRow";
// import Paper from "@mui/material/Paper";
// import EditIcon from "@mui/icons-material/Edit";
// import DeleteIcon from "@mui/icons-material/Delete";
// import EditEvent from "./EditEvent";
// import AddEvent from "./AddEvent";
// import { toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import Cookies from "js-cookie";
// import Snackbar from "@mui/material/Snackbar";
// import MuiAlert from "@mui/material/Alert";
// import { BASE_URL } from "./constants";

// // Function to fetch event details from API
// const fetchEventDetails = async () => {
//   try {
//     // Make API request to fetch event details
//     const isAdminCookie = Cookies.get('isAdmin');
//     const token = Cookies.get("token"); // Get the token from the cookie
//     let endpoint;
//     if (isAdminCookie.toString() === 'true') {
//       endpoint = `${BASE_URL}/api/v1/events-list/`; // Endpoint for admin
//     } else {
//       endpoint = `${BASE_URL}/api/v1/events/`; // Default endpoint
//     }
//     const response = await fetch(endpoint, {
//       headers: {
//         Authorization: `Bearer ${token}`, // Include the token in the Authorization header
//       },
//     });
//     if (!response.ok) {
//       throw new Error("Failed to fetch event details");
//     }
//     const eventData = await response.json();
//     console.log("e", eventData.state);
//     return eventData.results;
//   } catch (error) {
//     console.error("Error fetching event details:", error);
//     return [];
//   }
// };

// const EventDetails = () => {
//   const [eventData, setEventData] = useState([]);
//   const [showEdit, setShowEdit] = useState(false);
//   const [showAdd, setShowAdd] = useState(false);
//   const [selectedEvent, setSelectedEvent] = useState(null);
//   const [showSnackbar, setShowSnackbar] = useState(false);

//   useEffect(() => {
//     // Fetch event details when component mounts
//     fetchEventDetails().then((data) => {
//       setEventData(data);
//     });
//   }, []);

//   const handleEdit = (event) => {
//     // Handle edit action
//     setSelectedEvent(event);
//     setShowEdit(true);
//   };

//   const handleDelete = async (eventName, eventId) => {
//     // Handle delete action
//     if (
//       window.confirm(
//         "Are you sure you want to delete the event? This action cannot be reversed."
//       )
//     ) {
//       try {
//         const token = Cookies.get("token"); // Get the token from the cookie
//         const response = await fetch(`${BASE_URL}/api/v1/events/${eventId}/`, {
//           method: "DELETE",
//           headers: {
//             Authorization: `Bearer ${token}`, // Include the token in the Authorization header
//           },
//         });
//         if (!response.ok) {
//           throw new Error("Failed to delete event");
//         }

//         // Remove the deleted event from the state
//         setEventData((prevEventData) =>
//           prevEventData.filter((event) => event.id !== eventId)
//         );

//         // Show success toast
//         setShowSnackbar(true);
//       } catch (error) {
//         // Show error toast
//         toast.error("Error deleting event");
//         console.error("Error deleting event:", error);
//       }
//     }
//   };

//   const handleAddEvent = () => {
//     // Handle add event action
//     setShowAdd(true);
//   };

//   const handleCloseSnackbar = () => {
//     setShowSnackbar(false);
//   };

//   const stateMap = {
//     draft: "Draft",
//     in_review: "In-Review",
//     published: "Published",
//   };


//   const [expanded, setExpanded] = useState(false);

//   const handleClick = () => {
//     setExpanded(!expanded);
//   };

//   return (
//     <Box>
//       <Typography variant="h5" gutterBottom>
//         Event Details
//       </Typography>
//       <Button
//         variant="contained"
//         color="primary"
//         sx={{ mb: 2, float: "right" ,textTransform: 'none'}}
//         onClick={handleAddEvent}
//       >
//         Add an Event
//       </Button>
//       <TableContainer component={Paper}>
//         <Table>
//           <TableHead>
//             <TableRow>
//               <TableCell style={{ fontWeight: 'bold' }}>Event Name</TableCell>
//               <TableCell style={{ fontWeight: 'bold' }}>Description</TableCell>
//               <TableCell style={{ fontWeight: 'bold' }}>Room</TableCell>
//               <TableCell style={{ fontWeight: 'bold' }}>Start Date</TableCell>
//               <TableCell style={{ fontWeight: 'bold' }}>Start Time</TableCell>
//               <TableCell style={{ fontWeight: 'bold' }}>End Date</TableCell>
//               <TableCell style={{ fontWeight: 'bold' }}>End Time</TableCell>
//               <TableCell style={{ fontWeight: 'bold' }}>Event State</TableCell>
//               <TableCell style={{ fontWeight: 'bold' }}>Actions</TableCell>
//             </TableRow>
//           </TableHead>
//           <TableBody>
//             {eventData.map((event) => (
//               <TableRow key={event.name}>
//                 <TableCell>{event.name}</TableCell>
//                 <TableCell style={{ cursor: "pointer" }} onClick={handleClick}>
//                   {" "}
//                   {expanded
//                     ? event.stripped_description
//                     : `${event.stripped_description.slice(0, 50)}...`}
//                 </TableCell>
//                 {/* <TableCell style={{ cursor: "pointer" }} onClick={handleClick} >{event.stripped_description}</TableCell> */}
//                 {/* <TableCell style={{ maxWidth: 0, overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
//   {event.name}
// </TableCell> */}
//                 <TableCell>{event.room_name}</TableCell>
//                 <TableCell>{event.event_start_date}</TableCell>
//                 <TableCell>{event.event_start_time.slice(0, 5)}</TableCell>
//                 <TableCell>{event.event_end_date}</TableCell>
//                 <TableCell>{event.event_end_time.slice(0, 5)}</TableCell>
//                 <TableCell>{stateMap[event.state]}</TableCell> 
//                 <TableCell>
//                   <Box sx={{ display: "flex", flexDirection: "row" }}>
//                     <Button
//                       variant="outlined"
//                       startIcon={<EditIcon />}
//                       onClick={() => handleEdit(event)}
//                       sx = {{textTransform: 'none'}}
//                     >
//                       Edit
//                     </Button>
//                     <Button
//                       variant="outlined"
//                       startIcon={<DeleteIcon />}
//                       onClick={() => handleDelete(event.name, event.id)}
//                       sx={{ ml: 1,textTransform: 'none' }}
//                     >
//                       Delete
//                     </Button>
//                   </Box>
//                 </TableCell>
//               </TableRow>
//             ))}
//           </TableBody>
//         </Table>
//       </TableContainer>
//       {showEdit && (
//         <EditEvent event={selectedEvent} onClose={() => setShowEdit(false)} />
//       )}
//       {showAdd && <AddEvent onClose={() => setShowAdd(false)} />}
//       <Snackbar
//         open={showSnackbar}
//         autoHideDuration={6000}
//         onClose={handleCloseSnackbar}
//       >
//         <MuiAlert
//           elevation={6}
//           variant="filled"
//           onClose={handleCloseSnackbar}
//           severity="success"
//         >
//           Event deleted successfully
//         </MuiAlert>
//       </Snackbar>
//     </Box>
//   );
// };

// export default EventDetails;

import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import EditEvent from "./EditEvent";
import AddEvent from "./AddEvent";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Cookies from "js-cookie";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Pagination from "@mui/material/Pagination";
import { BASE_URL } from "./constants";

// Function to fetch event details from API
const fetchEventDetails = async () => {
  try {
    const isAdminCookie = Cookies.get('isAdmin');
    const token = localStorage.getItem('token') || Cookies.get('token') ;
    let endpoint;
    if (isAdminCookie.toString() === 'true') {
      endpoint = `${BASE_URL}/api/v1/events-list/`;
    } else {
      endpoint = `${BASE_URL}/api/v1/events/`;
    }
    const response = await fetch(endpoint, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      throw new Error("Failed to fetch event details");
    }
    const eventData = await response.json();
    return eventData.results;
  } catch (error) {
    console.error("Error fetching event details:", error);
    return [];
  }
};

const EventDetails = () => {
  const [eventData, setEventData] = useState([]);
  const [showEdit, setShowEdit] = useState(false);
  const [showAdd, setShowAdd] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  useEffect(() => {
    fetchEventDetails().then((data) => {
      setEventData(data);
    });
  }, []);

  const handleEdit = (event) => {
    setSelectedEvent(event);
    setShowEdit(true);
  };

  const handleDelete = async (eventName, eventId) => {
    if (window.confirm("Are you sure you want to delete the event? This action cannot be reversed.")) {
      try {
        const token = localStorage.getItem('token') || Cookies.get('token') ;
        const response = await fetch(`${BASE_URL}/api/v1/events/${eventId}/`, {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (!response.ok) {
          throw new Error("Failed to delete event");
        }

        setEventData((prevEventData) =>
          prevEventData.filter((event) => event.id !== eventId)
        );

        setShowSnackbar(true);
      } catch (error) {
        toast.error("Error deleting event");
        console.error("Error deleting event:", error);
      }
    }
  };

  const handleAddEvent = () => {
    setShowAdd(true);
  };

  const handleCloseSnackbar = () => {
    setShowSnackbar(false);
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const stateMap = {
    draft: "Draft",
    in_review: "In-Review",
    published: "Published",
  };

  const paginatedEvents = eventData.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);
  const totalPages = Math.ceil(eventData.length / itemsPerPage);

  return (
    <Box>
      <Typography variant="h5" gutterBottom>
        Event Details
      </Typography>
      <Button
        variant="contained"
        color="primary"
        sx={{ mb: 2, float: "right", textTransform: 'none' }}
        onClick={handleAddEvent}
      >
        Add an Event
      </Button>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ fontWeight: 'bold' }}>Event Name</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>Description</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>Room</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>Start Date</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>Start Time</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>End Date</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>End Time</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>Event State</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedEvents.map((event) => (
              <TableRow key={event.name}>
                <TableCell>{event.name}</TableCell>
                <TableCell style={{ cursor: "pointer" }}>
                  {event.stripped_description.length > 50
                    ? `${event.stripped_description.slice(0, 50)}...`
                    : event.stripped_description}
                </TableCell>
                <TableCell>{event.room_name}</TableCell>
                <TableCell>{event.event_start_date}</TableCell>
                <TableCell>{event.event_start_time.slice(0, 5)}</TableCell>
                <TableCell>{event.event_end_date}</TableCell>
                <TableCell>{event.event_end_time.slice(0, 5)}</TableCell>
                <TableCell>{stateMap[event.state]}</TableCell>
                <TableCell>
                  <Box sx={{ display: "flex", flexDirection: "row" }}>
                    <Button
                      variant="outlined"
                      startIcon={<EditIcon />}
                      onClick={() => handleEdit(event)}
                      sx={{ textTransform: 'none' }}
                    >
                      Edit
                    </Button>
                    <Button
                      variant="outlined"
                      startIcon={<DeleteIcon />}
                      onClick={() => handleDelete(event.name, event.id)}
                      sx={{ ml: 1, textTransform: 'none' }}
                    >
                      Delete
                    </Button>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        count={totalPages}
        page={currentPage}
        onChange={handlePageChange}
        sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}
      />
      {showEdit && (
        <EditEvent event={selectedEvent} onClose={() => setShowEdit(false)} />
      )}
      {showAdd && <AddEvent onClose={() => setShowAdd(false)} />}
      <Snackbar
        open={showSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleCloseSnackbar}
          severity="success"
        >
          Event deleted successfully
        </MuiAlert>
      </Snackbar>
    </Box>
  );
};

export default EventDetails;
