import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import Cookies from 'js-cookie';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Modal from '@mui/material/Modal';
import { BASE_URL } from './constants';
import { Editor } from '@tinymce/tinymce-react';
import CircularProgress from '@mui/material/CircularProgress'; 
import { Checkbox, FormControlLabel } from '@mui/material';

const EditRoomForm = ({ onClose, roomId }) => {
  const [roomData, setRoomData] = useState({
    name: '',
    description: '',
    room_thumbnail: '',
    intro_flyin_graphic: null,
    loop_graphic: null,
    support_audio:'',
    status: '',
    zoom_link: '',
    hub: false,
    create_event: false,
    state: 'draft'
  });
  const [events, setEvents] = useState([]);
  const [tinyEditors, setTinyEditors] = useState([]);
  const [snackbar, setSnackbar] = useState({
    open: false,
    severity: 'success',
    message: ''
  });

  const [modifiedFields, setModifiedFields] = useState({});
  const [tinymceData, setTinyMCEData] = useState([]);
  const [selectedEditor, setSelectedEditor] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [updatedName, setUpdatedName] = useState('');
  const [updatedContent, setUpdatedContent] = useState('');
  const [loading, setLoading] = useState(false); 
  const [clearFlyinGraphic, setClearFlyinGraphic] = useState(false);
  const [clearLoopGraphic, setClearLoopGraphic] = useState(false);
  const [clearAudio, setClearAudio] = useState(false);

  useEffect(() => {
    const fetchRoomData = async () => {
      try {
        const token = localStorage.getItem('token') || Cookies.get('token') ;
        const response = await fetch(`${BASE_URL}/api/v1/rooms/${roomId}/`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await response.json();
        if (!response.ok) {
          throw new Error('Failed to fetch room data');
        }
        setRoomData(data);
      } catch (error) {
        console.error('Error fetching room data:', error);
      }
    };

    fetchRoomData();
  }, [roomId]);

  const handleClearFlyinGraphicChange = (event) => {
    setClearFlyinGraphic(event.target.checked);
    if (event.target.checked) {
        setRoomData((prevData) => ({ ...prevData, intro_flyin_graphic: null }));
        setModifiedFields((prevFields) => ({ ...prevFields, intro_flyin_graphic: '' }));
    }
  };

  const handleClearLoopGraphicChange = (event) => {
      setClearLoopGraphic(event.target.checked);
      if (event.target.checked) {
          setRoomData((prevData) => ({ ...prevData, loop_graphic: null }));
          setModifiedFields((prevFields) => ({ ...prevFields, loop_graphic: '' }));
      }
  };
  const handleClearSupportAudioChange = (event) => {
    setClearAudio(event.target.checked);
    if (event.target.checked) {
        setRoomData((prevData) => ({ ...prevData, support_audio: null }));
        setModifiedFields((prevFields) => ({ ...prevFields, support_audio: '' }));
    }
  };

  const handleUpdateRoom = async () => {
    try {
      const token = Cookies.get('token');
      const formData = new FormData();
      setLoading(true); 
      // Append modified fields to formData
      for (const key in modifiedFields) {
        if (key === "name" && !modifiedFields[key].trim()) {
          window.alert("The Room Name field cannot be blank.");
          return; // Exit the function to prevent further processing
        }
        formData.append(key, modifiedFields[key]);
      }
      roomData.state = 'draft';
      formData.append('state', 'draft');
      const response = await fetch(`${BASE_URL}/api/v1/rooms/${roomId}/`, {
        method: 'PATCH',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Failed to update room');
      }

      // Show success Snackbar
      setSnackbar({ open: true, severity: 'success', message: 'Room updated successfully' });
    } catch (error) {
      console.error('Error updating room:', error);
      // Show error Snackbar
      setSnackbar({ open: true, severity: 'error', message: 'Failed to update room' });
      setLoading(false); // Stop loading on error
    } finally {
      setLoading(false); // Stop loading regardless of success or failure
    }
  };

  const handleEditContent = (editor) => {
    setSelectedEditor(editor);
    setUpdatedContent(editor.content);
    setUpdatedName(editor.name);
    setModalOpen(true);
  };

  const handleDeleteContent = async (id) => {
  try {
    console.log("iddd",id)
    const token = Cookies.get('token');
    const response = await fetch(`${BASE_URL}/api/v1/tiny-editor/${id}/`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error('Failed to delete editor');
    }

    // Show success Snackbar
    setSnackbar({ open: true, severity: 'success', message: 'Editor deleted successfully' });
    setModalOpen(false);
  } catch (error) {
    console.error('Error deleting editor:', error);
    // Show error Snackbar
    setSnackbar({ open: true, severity: 'error', message: 'Failed to delete editor' });
  }
};


  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleUpdateContent = async () => {
    try {
      const token = Cookies.get('token');
      const formData = new FormData();
      formData.append('name', updatedName);
      formData.append('content', updatedContent);
      
      const response = await fetch(`${BASE_URL}/api/v1/tiny-editor/${selectedEditor.id}/`, {
        method: 'PATCH',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Failed to update room content');
      }

      setSnackbar({ open: true, severity: 'success', message: 'Room content updated successfully' });
      setModalOpen(false);
    } catch (error) {
      console.error('Error updating room content:', error);
      setSnackbar({ open: true, severity: 'error', message: 'Failed to update room content' });
    }
  };

  const handleDeleteRoom = async () => {
    const confirmed = window.confirm("Are you sure you want to delete this room? This action cannot be reversed.");
    if (!confirmed) return;

    try {
      const token = Cookies.get('token');
      const response = await fetch(`${BASE_URL}/api/v1/rooms/${roomId}/`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error('Failed to delete room');
      }

      // Show success Snackbar
      setSnackbar({ open: true, severity: 'success', message: 'Room deleted successfully' });
    } catch (error) {
      console.error('Error deleting room:', error);
      // Show error Snackbar
      setSnackbar({ open: true, severity: 'error', message: 'Failed to delete room' });
    }
  };

  const handleTinyMCEChange = (content, name, id) => {
    // Handle editor change
    console.log("content",content);
    console.log("id",id);
    console.log("name",name);
    setTinyMCEData(prevData => [
      ...prevData,
      { id: id, content: content, name: name }
    ]);

    // Call your API here if needed
  };

  // const handleChange = (e) => {
  //   const { name, value, files } = e.target;

  //   if (files && files[0]) {
  //     const file = files[0];
  //     const reader = new FileReader();

  //     reader.onload = (event) => {
  //       setRoomData((prevRoomData) => ({
  //         ...prevRoomData,
  //         [name]: event.target.result,
  //       }));
  //     };

  //     reader.readAsDataURL(file);

  //     setModifiedFields((prevModifiedFields) => ({
  //       ...prevModifiedFields,
  //       [name]: file,
  //     }));
  //   } else {
  //     // Update both description and stripped_description
  //     setRoomData((prevRoomData) => ({
  //       ...prevRoomData,
  //       [name]: value,
  //       stripped_description: value.replace(/<[^>]+>/g, ''), // Strip HTML tags
  //     }));
  //     setModifiedFields((prevModifiedFields) => ({
  //       ...prevModifiedFields,
  //       [name]: value,
  //     }));
  //   }
  // };
  const handleChange = (e) => {
    const { name, value, files } = e.target;
  
    if (files && files[0]) {
      const file = files[0];
      const reader = new FileReader();
  
      reader.onload = (event) => {
        setRoomData((prevRoomData) => ({
          ...prevRoomData,
          [name]: event.target.result,
        }));
      };
  
      reader.readAsDataURL(file);
  
      setModifiedFields((prevModifiedFields) => ({
        ...prevModifiedFields,
        [name]: file,
      }));
    } else {
      // Update the state based on the name of the input field
      setRoomData((prevRoomData) => ({
        ...prevRoomData,
        [name]: value,
        // Only update stripped_description if the name is 'description'
        stripped_description: name === 'description' ? value.replace(/<[^>]+>/g, '') : prevRoomData.stripped_description,
      }));
      setModifiedFields((prevModifiedFields) => ({
        ...prevModifiedFields,
        [name]: value,
      }));
    }
  };
  

  const handleFileButtonClick = (fieldName) => {
    document.getElementById(fieldName).click();
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };


  useEffect(() => {
    const fetchTinyEditors = async () => {
      try {
        const token = localStorage.getItem('token') || Cookies.get('token') ;
        const response = await fetch(`${BASE_URL}/api/v1/tiny-editor/`);
        const data = await response.json();
        if (!response.ok) {
          throw new Error('Failed to fetch tiny editors');
        }
        // Filter out entries based on room ID
        const filteredEditors = data.results.filter(editor => editor.room === roomId);
        setTinyEditors(filteredEditors);
      } catch (error) {
        console.error('Error fetching tiny editors:', error);
      }
    };

    fetchTinyEditors();
  }, [roomId]);

  const handleUpdateRoomContent = async () => {
    try {
      const token = Cookies.get('token');
      const promises = tinymceData.map(({ id, content, name }) => {
        // Find the corresponding editor in tinyEditors based on ID
        console.log("id", id);
        console.log("name", name);
        const editor = tinyEditors.find(editor => editor.id === id);
        // console.log("sending", editor);
        if (!editor) {
          throw new Error(`Editor with ID ${id} not found in tinyEditors`);
        }
        
        const formData = new FormData();
        
        formData.append('name', name); // Use the name from tinyEditors
        formData.append('content', content);
        // console.log("sending", editor);
        
        return fetch(`${BASE_URL}/api/v1/tiny-editor//${id}/`, {
          method: 'PATCH',
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        });
      });
      
      await Promise.all(promises);

      // Show success Snackbar
      setSnackbar({ open: true, severity: 'success', message: 'Room content updated successfully' });
    } catch (error) {
      console.error('Error updating room content:', error);
      // Show error Snackbar
      setSnackbar({ open: true, severity: 'error', message: 'Failed to update room content' });
    }
  };

  const stateColors = {
    in_review: '#FFC107',
    draft: '#9E9E9E',
    published: '#4CAF50'
  };

  const handleRequestReview = async () => {
    try {
      // Get the current state of the room
    
      if (roomData.state === 'in_review') {
        alert('Room already in review');
        return;
      }
      
      if (roomData.state === 'published') {
        alert('Room already published');
        return;
      }
  
      if (roomData.state !== 'draft') {
        alert('Invalid room state');
        return;
      }
  
      const confirmed = window.confirm("Are you sure you want to request review by admin? This action cannot be reversed.");
  
      if (confirmed) {
        // Make a PATCH request to update the room state
        roomData.state = 'in_review';
        const token = localStorage.getItem('token') || Cookies.get('token') ;
        const response = await fetch(`${BASE_URL}/api/v1/rooms/${roomId}/`, {
          method: 'PATCH',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ state: 'in_review' }), // Update room state to "in_review"
        });
  
        if (!response.ok) {
          throw new Error('Failed to request review');
        }
  
        alert('Room sent for review\nRefresh page to see the changes');

      }
    } catch (error) {
      console.error('Error requesting review:', error);
      alert('Failed to request review');
    }
  };
  

  return (
    <Box p={4}>
      <Typography variant="h4" gutterBottom>Edit Room</Typography>
      <Box textAlign="center">
        <Box
          bgcolor={stateColors[roomData.state]}
          color="#fff"
          borderRadius={16}
          px={2}
          py={1}
          ml={2}
          mb={2}
          display="inline-block"
        >
          Current state of room: {roomData.state === 'in_review' ? 'In - Review' : roomData.state.charAt(0).toUpperCase() + roomData.state.slice(1)}
        </Box>
      </Box>
      
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '20px' }}>
        <Button variant="contained" color="primary" id="request-review-button" sx ={{textTransform: 'none'}} onClick={handleRequestReview}>
          Request Review
        </Button>
        <Box mx={2}></Box>
        <Button variant="contained" onClick={onClose} startIcon={<ArrowBackIcon />} sx={{ bgcolor: 'yellow', color: '#000000',textTransform: 'none',}}>
          Go Back
        </Button>
      </div>
      <div>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
          <Typography variant="subtitle1" style={{ width: '150px' }}>Room Name*</Typography>
          <TextField
            name="name"
            value={roomData.name}
            onChange={handleChange}
            fullWidth
          />
        </div>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
          <Typography variant="subtitle1" style={{ width: '150px' }}>Description</Typography>
          <TextField
            name="description"
            value={roomData.stripped_description}
            onChange={handleChange}
            fullWidth
            multiline
            rows={4}
          />
        </div>
        {/* Room Thumbnail */}
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
          <Typography variant="subtitle1" style={{ width: '150px' }}>Room Thumbnail*</Typography>
          <Box display="flex" alignItems="center">
            <img
              src={roomData.room_thumbnail}
              alt="Room Thumbnail"
              id="room_thumbnail_preview"
              style={{ width: '150px', marginRight: '12px' }}
            />
            <Button
              variant="contained"
              onClick={() => handleFileButtonClick('room_thumbnail')}
              sx ={{ textTransform: 'none'}}
            >
              {roomData.room_thumbnail ? 'Update Room Thumbnail' : 'Upload Room Thumbnail'}
            </Button>
            <input
              type="file"
              accept="image/*"
              onChange={handleChange}
              style={{ display: 'none' }}
              id="room_thumbnail"
              name="room_thumbnail"
            />
          </Box>
        </div>
        {/* Intro Flyin Graphic */}
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
          <Typography variant="subtitle1" style={{ width: '150px' }}>Intro Flyin Graphic</Typography>
          <Box display="flex" alignItems="center">
            {roomData.intro_flyin_graphic && (
              <img
                src={roomData.intro_flyin_graphic}
                alt="Intro Flyin Graphic"
                style={{ width: '150px', marginRight: '12px' }}
              />
            )}
            <Button
              variant="contained"
              onClick={() => handleFileButtonClick('intro_flyin_graphic')}
              sx ={{ textTransform: 'none'}}
            >
              {roomData.intro_flyin_graphic ? 'Update Intro Flyin Graphic' : 'Upload Intro Flyin Graphic'}
            </Button>
            {roomData.intro_flyin_graphic && (
             <FormControlLabel
             control={<Checkbox checked={clearFlyinGraphic} onChange={handleClearFlyinGraphicChange} />}
             label="Clear Intro Flyin Graphic"
             sx={{ marginLeft: '20px' }} // Add marginLeft to create space
           />
           )}
            <input
              type="file"
              accept="image/*,video/*"
              onChange={handleChange}
              style={{ display: 'none' }}
              id="intro_flyin_graphic"
              name="intro_flyin_graphic"
            />
          </Box>
        </div>
        {/* Loop Graphic */}
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
          <Typography variant="subtitle1" style={{ width: '150px' }}>Loop Graphic</Typography>
          <Box display="flex" alignItems="center">
            {roomData.loop_graphic && (
              <img
                src={roomData.loop_graphic}
                alt="Loop Graphic"
                style={{ width: '150px', marginRight: '12px' }}
              />
            )}
            <Button
              variant="contained"
              onClick={() => handleFileButtonClick('loop_graphic')}
              sx ={{ textTransform: 'none'}}
            >
              {roomData.loop_graphic ? 'Update Loop Graphic' : 'Upload Loop Graphic'}
            </Button>
            {roomData.loop_graphic && (
            <FormControlLabel
                control={<Checkbox checked={clearLoopGraphic} onChange={handleClearLoopGraphicChange} />}
                label="Clear Loop Graphic"
                sx={{ marginLeft: '20px' }}
            />
            )}
            <input
              type="file"
              accept="image/*,video/*"
              onChange={handleChange}
              style={{ display: 'none' }}
              id="loop_graphic"
              name="loop_graphic"
            />
          </Box>
        </div>
        {/* Support Audio */}
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
            <Typography variant="subtitle1" style={{ width: '150px' }}>Support Audio</Typography>
            <Box display="flex" alignItems="center">
              {/* Audio Player */}
              {roomData.support_audio && (
                <audio controls id="support_audio_preview" style={{ marginRight: '12px' }}>
                  <source src={roomData.support_audio} type="audio/mp3" />
                  Your browser does not support the audio element.
                </audio>
              )}
              {/* Upload Button */}
              <Button
                variant="contained"
                onClick={() => handleFileButtonClick('support_audio')}
                sx ={{ textTransform: 'none'}}
              >
                {roomData.support_audio ? 'Update Support Audio' : 'Upload Support Audio'}
              </Button>
              {roomData.support_audio && (
              <FormControlLabel
                control={<Checkbox checked={clearAudio} onChange={handleClearSupportAudioChange} />}
                label="Clear Support Audio"
                sx={{ marginLeft: '20px' }}
              />
              )}
              {/* Input for uploading */}
              <input
                type="file"
                accept="audio/*, .mp3"
                onChange={handleChange}
                style={{ display: 'none' }}
                id="support_audio"
                name="support_audio"
              />
            </Box>
          </div>
        {/* <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
          <Typography variant="subtitle1" style={{ width: '150px' }}>Support Audio</Typography>
          <Box display="flex" alignItems="center">
            <audio controls id="support_audio_preview" style={{ marginRight: '12px' }}>
              <source src={roomData.support_audio} type="audio/mp3" />
              Your browser does not support the audio element.
            </audio>
            <Button
              variant="contained"
              onClick={() => handleFileButtonClick('support_audio')}
            >
              Upload Support Audio
            </Button>
            <input
              type="file"
              accept="audio/*"
              onChange={handleChange}
              style={{ display: 'none' }}
              id="support_audio"
              name="support_audio"
            />
          </Box>
        </div> */}
      </div>
      <Box bgcolor="lightblue" p={2} textAlign="center" mt={4} mb={2}>
        Events
      </Box>
      <TableContainer component={Paper} mb={4}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell><b>Event Name</b></TableCell>
              <TableCell><b>Description</b></TableCell>
              <TableCell><b>Start Date</b></TableCell>
              <TableCell><b>Start Time</b></TableCell>
              <TableCell><b>End Date</b></TableCell>
              <TableCell><b>End Time</b></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {events.map((event, index) => (
              <TableRow key={index}>
                <TableCell>{event.name}</TableCell>
                <TableCell>{event.description}</TableCell>
                <TableCell>{event.event_start_date}</TableCell>
                <TableCell>{event.event_start_time}</TableCell>
                <TableCell>{event.event_end_date}</TableCell>
                <TableCell>{event.event_end_time}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box bgcolor="lightblue" p={2} textAlign="center" mb={2}>
        TinyMCE Editor
      </Box>
      <TableContainer component={Paper} mb={4}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell><b>Name</b></TableCell>
              {/* <TableCell><b>Editor</b></TableCell> */}
              <TableCell><b>Action</b></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tinyEditors.map((editor, index) => (
              <TableRow key={index}>
                <TableCell>{editor.name}</TableCell>
                {/* <TableCell>
                  <Editor
                    apiKey="jwhx1c91nef9f7mcweeqb4oo3w8x6anij046gw5xf80r0l0n"
                    initialValue={editor.content}
                    init={{
                      height: 200,
                      menubar: false,
                      plugins: ['advlist autolink lists link image', 'charmap print preview anchor help', 'searchreplace visualblocks code', 'insertdatetime media table paste wordcount'],
                      toolbar: 'undo redo | formatselect | bold italic | alignleft aligncenter alignright | bullist numlist outdent indent | help'
                    }}
                    onEditorChange={(content) => handleTinyMCEChange(content, editor.id, editor.name)}
                    disabled={true} // Disable editing in the main table
                  />
                </TableCell> */}
                <TableCell>
                   <div style={{ display: 'flex', justifyContent: 'left', marginTop: '20px' }}>
                    <Button variant="contained" color="primary" sx ={{ textTransform: 'none'}} onClick={() => handleEditContent(editor)}>Edit Content</Button>
                    <Box mx={2}></Box>
                    <Button variant="contained" color="error" startIcon={<DeleteIcon />} sx ={{ textTransform: 'none'}} onClick={() => handleDeleteContent(editor.id)}>Delete</Button>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {/* Edit content modal */}
      <Modal
        open={modalOpen}
        onClose={handleCloseModal}
        aria-labelledby="edit-content-modal"
        aria-describedby="edit-content-modal-description"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '50%',
          bgcolor: 'white',
          boxShadow: 24,
          p: 4,
        }}>
          <Typography variant="h5" gutterBottom>{selectedEditor && selectedEditor.name}</Typography>
          <TextField
            label="Name"
            value={updatedName}
            onChange={(e) => setUpdatedName(e.target.value)}
            fullWidth
            style={{ marginBottom: '20px' }}
          />
          <Editor
            apiKey="jwhx1c91nef9f7mcweeqb4oo3w8x6anij046gw5xf80r0l0n" 
            initialValue={selectedEditor && selectedEditor.content}
            init={{
              height: 400,
              menubar: false,
              plugins: ['advlist autolink lists link image', 'charmap print preview anchor help', 'searchreplace visualblocks code', 'insertdatetime media table paste wordcount'],
              toolbar: 'undo redo | formatselect | bold italic | alignleft aligncenter alignright | bullist numlist outdent indent | help'
            }}
            onEditorChange={(content) => setUpdatedContent(content)}
          />
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
            <Button variant="contained" color="primary" sx ={{ textTransform: 'none'}} onClick={handleUpdateContent}>Update Content</Button>
          </Box>
        </Box>
      </Modal>
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
        <Button variant="contained" color="primary" sx ={{ textTransform: 'none'}} onClick={handleUpdateRoom}  disabled={loading}> {loading && <CircularProgress size={24} style={{ position: 'absolute', top: '50%', left: '50%', marginTop: -12, marginLeft: -12, color: 'white' }} />} Update</Button>
        <Box mx={2}></Box>
        <Button variant="contained" color="error" startIcon={<DeleteIcon />} sx ={{ textTransform: 'none'}} onClick={handleDeleteRoom}>Delete</Button>
      </div>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
        >
          {snackbar.message}
        </MuiAlert>
      </Snackbar>
    </Box>
  );
};

export default EditRoomForm;
