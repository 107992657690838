import React from 'react';
import { Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const RoomComponents = ({ onClose, roomData }) => {
    const formatType = (component) => {
        if (component.artwork) {
          return 'Image Container';
        } else if (component.embed_link) {
          return 'Youtube';
        }else if (component.magazine_url) {
          return 'Magazine';
        } else if (component.navigation_type && component.navigation_type.toLowerCase() === 'external') {
          return 'External Hotspot';
        } else if (component.navigation_type && component.navigation_type.toLowerCase() === 'internal') {
          return 'Internal Hotspot';
        } else if (component.content) {
          return 'WYSIWYG Editor';
        } else if (component.name === 'info') {
          return 'Room-Info';
        } else {
          return 'Internal Hotspot';
        }
    };
    

  // Function to format date
  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };
    return new Date(dateString).toLocaleDateString('en-US', options);
  };

  // Function to format state
  const formatState = (state) => {
    switch (state) {
      case 'draft':
        return 'Draft';
      case 'in_review':
        return 'In-Review';
      case 'published':
        return 'Published';
      default:
        return state;
    }
  };

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '16px' }}>
        <Typography variant="h5" gutterBottom>
          Room Components
        </Typography>
        <Button variant="contained" onClick={onClose} startIcon={<ArrowBackIcon />} sx={{ bgcolor: 'yellow', color: '#000000' }}>
          Go Back
        </Button>
      </div>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ fontWeight: 'bold' }}>Name</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>Created At</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>Type</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {/* Iterate over room_embedcontainer */}
            {roomData.room_embedcontainer.map((component) => (
              <TableRow key={component.id}>
                <TableCell>{component.name}</TableCell>
                <TableCell>{formatDate(component.created_at)}</TableCell>
                <TableCell>{formatType(component)}</TableCell>
                <TableCell>{formatState(component.state)}</TableCell>
              </TableRow>
            ))}
            {/* Iterate over room_imagecontainer */}
            {roomData.room_imagecontainer.map((component) => (
              <TableRow key={component.id}>
                <TableCell>{component.name}</TableCell>
                <TableCell>{formatDate(component.created_at)}</TableCell>
                <TableCell>{formatType(component)}</TableCell>
                <TableCell>{formatState(component.state)}</TableCell>
              </TableRow>
            ))}
            {/* Iterate over room_imagehotspot */}
            {roomData.room_imagehotspot.map((component) => (
              <TableRow key={component.id}>
                <TableCell>{component.name}</TableCell>
                <TableCell>{formatDate(component.created_at)}</TableCell>
                <TableCell>{formatType(component)}</TableCell>
                <TableCell>{formatState(component.state)}</TableCell>
              </TableRow>
            ))}
            {roomData.room_magazine.map((component) => (
              <TableRow key={component.id}>
                <TableCell>{component.name}</TableCell>
                <TableCell>{formatDate(component.created_at)}</TableCell>
                <TableCell>{formatType(component)}</TableCell>
                <TableCell>{formatState(component.state)}</TableCell>
              </TableRow>
            ))}
            {/* Iterate over room_info */}
            {roomData.room_info.map((component) => (
              <TableRow key={component.id}>
                <TableCell>{component.name}</TableCell>
                <TableCell>{formatDate(component.created_at)}</TableCell>
                <TableCell>Room-Info</TableCell>
                <TableCell>{formatState(component.state)}</TableCell>
              </TableRow>
            ))}
            {/* Iterate over room_tinymcecontent */}
            {roomData.room_tinymcecontent.map((component) => (
              <TableRow key={component.id}>
                <TableCell>{component.name}</TableCell>
                <TableCell>{formatDate(component.created_at)}</TableCell>
                <TableCell>WYSIWYG Editor</TableCell>
                <TableCell>{formatState(component.state)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default RoomComponents;
