// ResetPasswordForm.js
import React, { useState } from 'react';
import {  useParams } from 'react-router-dom';
import {
  TextField,
  Button,
  Grid,
  Paper,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  IconButton,
  InputAdornment,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { BASE_URL } from './constants';

const ResetPasswordForm = ({ backgroundImageUrl }) => {
  const {UID,token} = useParams();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [passwordError, setPasswordError] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);


  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    setPasswordError('');
    if (e.target.value.length < 6) {
      setPasswordError('Password must be at least 6 characters long');
      setIsSaveDisabled(true);
    } else {
      setPasswordError('');
      setIsSaveDisabled(confirmPassword !== e.target.value);
    }
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
    setConfirmPasswordError('');
    if (e.target.value !== password) {
      setConfirmPasswordError('Passwords do not match');
      setIsSaveDisabled(true);
    } else {
      setConfirmPasswordError('');
      setIsSaveDisabled(false);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };


  const handleSave = async () => {
    try {
      const response = await fetch(`${BASE_URL}/account/reset-password/${UID}/${token}/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ password, password2: confirmPassword }),
      });
  
      if (!response.ok) {
        throw new Error('Failed to reset password');
      }
  
      setOpenDialog(true); // Open dialog on successful password reset
    } catch (error) {
      console.error('Error resetting password:', error);
      // Handle error (e.g., display error message)
    }
  };
  

  const handleCloseDialog = () => {
    setOpenDialog(false);
    // Redirect to login page after password reset
    window.location.href = '/login';
  };



  return (
    <Grid container justifyContent="center" alignItems="center" style={{ height: '100vh' }}>
      <Grid item xs={10} sm={8} md={6} lg={4}>
        <Typography variant="h5" align="center" gutterBottom style={{ fontFamily: 'Montserrat, sans-serif', fontWeight: 700 }}>
          Reset Password
        </Typography>
        <Paper
          elevation={3}
          style={{
            padding: 20,
            height: 400,
            backgroundColor: 'white',
            backgroundImage: `url(${backgroundImageUrl})`,
            backgroundSize: 'cover',
          }}
        >
          <TextField
            label="New Password"
            type={showPassword ? 'text' : 'password'}
            fullWidth
            margin="normal"
            value={password}
            error={!!passwordError}
            helperText={passwordError}
            onChange={handlePasswordChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={togglePasswordVisibility}>
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <TextField
            label="Confirm Password"
            type={showConfirmPassword ? 'text' : 'password'}
            fullWidth
            margin="normal"
            value={confirmPassword}
            error={!!confirmPasswordError}
            helperText={confirmPasswordError}
            onChange={handleConfirmPasswordChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={toggleConfirmPasswordVisibility}>
                    {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <Button
            variant="contained"
            style={{ backgroundColor: 'black', color: 'white', marginTop: 20, height: 55 }}
            fullWidth
            onClick={handleSave}
            disabled={isSaveDisabled}
          >
            Save
          </Button>
          {confirmPasswordError && (
            <Typography variant="body2" color="error" style={{ marginTop: 10, textAlign: 'center' }}>
              {confirmPasswordError}
            </Typography>
          )}
        </Paper>
      </Grid>

      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Password Reset Successful</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Your password has been reset successfully. You will now be redirected to the login page.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default ResetPasswordForm;
